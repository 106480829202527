<template>
    <div style="height: 100%;overflow: auto">
        <div>
            <div class="arbitrage_record">

                <div class="pages_header">
                    <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
                    <span class="App_header_title"> {{ $t('trusteeshipDetailPage.托管详细') }} </span>
                </div>

                <div class="switch_container">
                    <div class="switch_content">
                        <div class="switch_item" @click="switchToggle('direct')" v-if="switchDirect"><span>{{
                                $t('trusteeshipDetailPage.主持') }}</span></div>
                        <div class="switch_item active" @click="switchToggle('direct_active')"
                            v-if="switchDirectActive"><span>{{ $t('trusteeshipDetailPage.主持') }}</span></div>

                        <div class="switch_item" @click="switchToggle('terminate')" v-if="switchTerminate"><span>{{
                                $t('trusteeshipDetailPage.终止') }}</span></div>
                        <div class="switch_item active" @click="switchToggle('terminate_active')"
                            v-if="switchTerminateActive"><span>{{ $t('trusteeshipDetailPage.终止') }}</span></div>

                        <div class="switch_item" @click="switchToggle('redemption')" v-if="switchRedemption"><span>{{
                                $t('trusteeshipDetailPage.赎回') }}</span></div>
                        <div class="switch_item active" @click="switchToggle('redemption_active')"
                            v-if="switchRedemptionActive"><span>{{ $t('trusteeshipDetailPage.赎回') }}</span></div>
                    </div>
                </div>

                <div class="switch_content"></div>

                <div>
                    <div class="no_data_content ff_NunitoSemiBold" style="min-height: calc(-260px + 100vh);"
                        v-if="ArrList.length == 0">
                        <img src="@/assets/imgs/noData-icon.png" alt="" class="img_no_data" />
                        <div class="no_data_text">{{ $t('trusteeshipDetailPage.无数据') }}</div>
                    </div>

                    <div class="RecordDeList">
                        <van-list v-model:loading="loading" :finished="finished" @load="onLoad">
                            <div v-for="(item, index) in ArrList" :key="index" class="RecordDeRow">
                                <div>
                                    <div style="text-align: left;">
                                        <div>{{ $t("订单号") }}: {{ item.order_no }}</div>
                                    </div>

                                    <div class="Row">
                                        <div>{{ $t("产品名称") }}</div>
                                        <div>{{ item.name }}</div>
                                    </div>

                                    <div class="Row">
                                        <div>{{ $t("日收益率") }}</div>
                                        <div>{{ item.day_rate }}%</div>
                                    </div>

                                    <div class="Row">
                                        <div>{{ $t("预计收益") }}</div>
                                        <div>{{ Number(item.day_rate * item.period / 100 * item.amount) }}</div>
                                    </div>

                                    <div class="Row">
                                        <div>{{ $t("购买日期") }}</div>
                                        <div>{{ item.start_time }}</div>
                                    </div>

                                    <div class="Row">
                                        <div>{{ $t("结束日期") }}</div>
                                        <div>{{ item.end_time }}</div>
                                    </div>
                                </div>

                                <div style="margin: 15px 20px;">
                                    <div @click="CloseRow(item)" style="display: flex;justify-content: center;align-items: center;
                                        background: #1a9ffd;height: 38px;border-radius: 15px;
                                    ">{{ $t("取消托管") }}</div>
                                </div>
                            </div>
                        </van-list>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <ServiceComponents />
</template>

<script setup>
import ServiceComponents from '@/components/ServiceComponents.vue';
import '@/assets/css/trading_record.css';
import { useRouter } from "vue-router";
import {onMounted, ref} from "vue";
import { refresh } from '@/common/way';
import { getTuoGRecord, CloseTuoG } from '@/assets/js/api/interface';

const router = useRouter();

const switchDirect = ref(false);
const switchDirectActive = ref(true);
const switchTerminate = ref(true);
const switchTerminateActive = ref(false);
const switchRedemption = ref(true);
const switchRedemptionActive = ref(false);

const finished = ref(false);
const loading = ref(false)
const ArrList = ref([])
let Pageindex = 0;
const status = ref(1);

function switchToggle(str){
  if (str === 'direct'){
    switchDirectActive.value = true;
    switchDirect.value = false;
    switchTerminateActive.value = false;
    switchTerminate.value = true;
    switchRedemptionActive.value = false;
    switchRedemption.value = true;
      status.value = 1;
  }
  if (str === 'terminate'){
    switchTerminateActive.value = true;
    switchTerminate.value = false;
    switchDirectActive.value = false;
    switchDirect.value = true;
    switchRedemptionActive.value = false;
    switchRedemption.value = true;
      status.value = 2;
  }
  if (str === 'redemption'){
    switchRedemptionActive.value = true;
    switchRedemption.value = false;
    switchDirectActive.value = false;
    switchDirect.value = true;
    switchTerminateActive.value = false;
    switchTerminate.value = true;
      status.value = 3;
  }
    Pageindex = 0;
    ArrList.value = [];
    loading.value = false;
    finished.value = false;
    onLoad();
  refresh();
}

// 获取数据
async function GetArrData() {
    const arr = await getTuoGRecord({ page: Pageindex, size: 6, status: status.value })
    if (arr.data) {
        ArrList.value.push(...(arr.data))

        if (arr.data.length < 7) {
            finished.value = true;
            loading.value = false;
        }
    }
}

// 下拉数据
async function onLoad() {
    Pageindex++;
    await GetArrData();
    loading.value = false;
}

// 取消托管
async function CloseRow(data){
    await CloseTuoG({ order_no: data.order_no });
    Pageindex = 0;
    ArrList.value = [];
    loading.value = false;
    finished.value = false;
    onLoad();
    refresh();
}

onMounted(()=>{
  refresh();
})
</script>

<style scoped>
.van-list {
    display: flex;
    flex-direction: column;
    gap: 25px;
    box-sizing: border-box;
    padding: 0 15px;
    color: white;
}

.RecordDeRow{
    border: 1px solid white;
    border-radius: 15px;
    padding: 15px;

}

.Row{
    display: flex;
    justify-content: space-between;
}
</style>
