<template>
  <div class="home-container">
    <div class="an-home">

      <div class="top-wrapper">
        <div class="top_container">
          <div class="header">
            <img src="@/assets/imgs/languages.png" alt="" class="languages" @click="router.push('/languages')"/>
            <div class="news-icon">
              <div @click="router.push('/notice')">
                <img src="@/assets/imgs/icon_bell.svg" alt=""/>
              </div>
            </div>
            <img src="@/assets/imgs/my.png" alt="" class="inco-box" @click="showLeft = true"/>
          </div>
          <div class="logo-box">
            <div class="logo"><img src="@/assets/imgs/rr53071.png" alt=""></div>
            <div class="title-box">
              <van-notice-bar scrollable style="width: 100%;background: transparent;padding: 0;color: rgb(255, 255, 255)">
                <div class="title-box_title">KuCoin Pro</div>
              </van-notice-bar>
              <div class="title-info over-line-5">{{ $t('homePage.开始赚钱计划') }}</div>
            </div>
          </div>
        </div>

        <div class="banner-box">
          <div class="box-header">
            <div class="header-icon"></div>
            <div class="header-text">{{ $t('homePage.快速进入') }}</div>
          </div>
          <van-swipe lazy-render class="list_wrapper">
            <van-swipe-item v-for="(text,index) in textData" :key="index">
              <div class="list_wrapper_item" @click="quickEntry(text.type)">
                <h2 class="item_title"> {{ text.textTitle }} </h2>
                <div class="item_text"> {{ text.textContent }} </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>

      <div class="market_container">
        <div>
          <header class="market-header">
            <div class="header-icon"></div>
            <div class="header-text">{{ $t('homePage.市场') }}</div>
          </header>
          <div class="market-tabs">
            <div class="market-tab">
              <img ref="coin" src="@/assets/imgs/coin.png" alt="" />
              <img ref="coin_noSelect" src="@/assets/imgs/coin_noSelect.png" alt="" style="display: none" @click="marketSelectTab('coin')" />
              <div v-if="showCoin" class="inner-box-name">{{ $t('homePage.数字货币') }}</div>
            </div>
            <div class="market-tab">
              <img ref="amountIncrease_noSelect" src="@/assets/imgs/amountIncrease_noSelect.png" alt="" @click="marketSelectTab('amountIncrease')" />
              <img ref="amountIncrease" src="@/assets/imgs/amountIncrease.png" alt="" style="display: none" />
              <div v-if="showAmountIncrease" class="inner-box-name">{{ $t('homePage.涨幅') }}</div>
            </div>
          </div>

          <van-list v-model:loading="loading" :finished="finished" finished-text="Load complete"  @load="onLoad">
            <div>
              <div class="market_pro_list" v-for="(coin,index) in coinDataPage" :key="index">
                <div class="market_pro_item" @click="router.push({name:'coin_detail',params:{coin:coin.symbol2}})">
                  <div class="market_pro_base">
                    <img :src="getImageUrl(coin.logo)" alt="" />
                    <div>
                      <div class="market_pro_title">{{ coin.symbol }} Coin</div>
                      <div class="market_pro_subtitle">{{ coin.unit }}</div>
                    </div>
                  </div>
                  <div class="market_pro_line">
                    <div class="lineBoard" style="user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); position: relative;" :id="coin.symbol"></div>
                  </div>
                  <div class="market_pro_detail">
                    <div class="market_pro_price">US<span>$</span> {{ coin.price }} </div>
                    <div class="market_pro_change">
                      <span class="change_value fc-8CC351 m-r-10" v-if="parseFloat(coin.increase)<0" style="color: #ff2d6d">{{ coin.increase }}%</span>
                      <span class="change_value fc-8CC351 m-r-10" v-else style="color: #36ffdb">{{ coin.increase }}%</span>
                      <span class="period">24 Hrs</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>

        </div>
      </div>

      <div class="invite_container">
        <div class="box-header">
          <div class="header-icon"></div>
          <div class="header-text">{{$t('homePage.邀请朋友')}}</div>
        </div>
        <div class="invite_content">
          <h2 class="invite_title"> {{$t('homePage.邀请朋友加入')}} </h2>
          <div class="invite_text"> {{$t('homePage.开始邀请')}} </div>
          <div class="invite_btn flex-center" @click="router.push('/invite_friends')"> {{$t('homePage.查看')}} </div>
        </div>
      </div>

    </div>
  </div>

  <LeftMenu v-model:showLeft="showLeft" />
  <ServiceComponents/>
</template>

<script setup>
import '@/assets/css/home.css';
import ServiceComponents from '@/components/ServiceComponents.vue';
import LeftMenu from '@/components/LeftMenu.vue';
import { useRouter,useRoute } from 'vue-router';
import {onBeforeUnmount, onMounted, ref, watch} from "vue";
import {getAreaData} from "@/assets/js/api/chart";
import { useI18n } from 'vue-i18n';
import {getProductList, login} from "@/assets/js/api/interface";
import {connectionWebSocket} from "@/common/way";

const router = useRouter();
const { t } = useI18n();

//左侧弹出层
const showLeft = ref(false);

const textData = [
  {
    "textTitle": t('homePage.Ai智能套利'),
    "textContent": t('homePage.200家交易所的智能交易'),
    "type": "arbitrage"
  },
  {
    "textTitle": t('homePage.矿机租赁'),
    "textContent": t('homePage.效率高,回报快'),
    "type": "mining"
  }
]

//快速进入
function quickEntry(type){
  if (type==='arbitrage'){
    router.push('/arbitrage')
  }
  if (type==='mining'){
     router.push('/mining')
  }
}

const type = ref("");
const coinData = ref([]);
const coinDataPage = ref([]);
let per_page = 10;
let page = 1;
const socket = ref();//websocket连接

const coin = ref(null);
const coin_noSelect = ref(null);
const amountIncrease = ref(null);
const amountIncrease_noSelect = ref(null);
const showCoin = ref(true);
const showAmountIncrease = ref(false);
function marketSelectTab(tab){
  if (tab === 'amountIncrease'){
    coin.value.style.display = 'none';
    coin_noSelect.value.style.display = '';
    amountIncrease_noSelect.value.style.display = 'none';
    amountIncrease.value.style.display = '';
    showCoin.value = false;
    showAmountIncrease.value = true;
    //获取货币数据
    per_page = 10;
    page = 1;
    getCoin('DESC','increase');
  }
  if (tab === 'coin') {
    coin.value.style.display = '';
    coin_noSelect.value.style.display = 'none';
    amountIncrease_noSelect.value.style.display = '';
    amountIncrease.value.style.display = 'none';
    showCoin.value = true;
    showAmountIncrease.value = false;
    //获取货币数据
    per_page = 10;
    page = 1;
    getCoin('ASC', 'id');
  }
  type.value = tab;
}

let coinType = 'coin'
const getCoin = async (sort,sort_column) => {
  try {
    const data = await getProductList(per_page,page,coinType,sort);
    if (data.data.data.length < 9){
      finished.value = true;
    }
    if (page>1){
      if (data.data.data.length!==0){
        updataFormat(data.data.data,false)
        getAreaData(data.data.data)
        for (let i = 0; i < data.data.data.length; i++) {
          coinDataPage.value.push(data.data.data[i])
        }
      }
    }else {
      coinDataPage.value = data.data.data
      updataFormat(coinDataPage.value,false)
      getAreaData(coinDataPage.value)
    }

    const params = JSON.stringify({
      "sort" : sort,
      "sort_column" : sort_column
    })
    socket.value.send(params)
    socket.value.onmessage = (event) => {
      coinData.value = JSON.parse(event.data);
      updataFormat(coinData.value, true)
    }
  }catch (error) {
    console.error(error)
  }
}

//获取图片地址
const getImageUrl = (image) => {
  if (!image)
    return '';
  if (image && image.indexOf('http') != -1) {
    return image;
  } else {
    return "http://103.197.158.7:88/uploads" + image;
  }
}

function updataFormat(data,bol){
  for (let i = 0; i <data.length; i++) {
    data[i].price = parseFloat(data[i].price).toLocaleString('en-US');//去除无用零且进行千分制转换
    data[i].symbol2 = data[i].symbol
    if (data[i].symbol.includes('USDT')){
      data[i].symbol = data[i].symbol.replace('USDT','').trim();
    }
    if (data[i].symbol.includes('USD')){
      data[i].symbol = data[i].symbol.replace('USD','').trim();
    }
  }
  //定时器数据更新->更新分页数据
  if (bol){
    if (coinDataPage.value.length!==0){
      // 创建B数据的symbol索引
      const bMap = new Map(coinDataPage.value.map(item => [item.symbol, item]));
      // 更新B数据
      coinDataPage.value = [];
      for (const item of coinData.value) {
        if (bMap.get(item.symbol)){
          coinDataPage.value.push(item)
        }
      }
      console.log("定时器更新"+coinDataPage.value.length)
    }
  }
}

const loading = ref(false);
const finished  = ref(false);
let count = 0;
//进行滑动加载数据
const onLoad = async () =>{
  if (count>0){
    page+=1;
  }
  try {
     let sort = '';
     let sort_column = 'id'
      if (type.value==='amountIncrease'){
        sort = 'DESC';
        sort_column = 'increase'
      }
      //获取货币数据
      await getCoin(sort,sort_column);
  }catch (error) {
    console.error("数据获取失败:"+error)
  }finally {
    count++;
    console.log(count)

    // 加载状态结束
    loading.value = false;

    if (coinDataPage.value.length === coinData.value.length){
      finished.value = true;
    }
  }
}

onMounted( ()=>{
  let domArr = document.querySelectorAll('.lineBoard');
  domArr.forEach((item) => {
    const child = item.children;
    for(let i = 0; i < child.length; i++){
      child[i].remove();
    }
  })
  //连接定时更新货币数据服务
  // socket.value = connectionWebSocket('ws://103.197.158.7:2390');
  socket.value = connectionWebSocket('wss://api.kucoinbyftrz.com/ws1');
  const paramBol = localStorage.getItem('paramBol');
  if (paramBol==='true'){
    showLeft.value = true;
    localStorage.removeItem('paramBol');
  }
})

onBeforeUnmount(()=>{
  if (socket.value){
    socket.value.close();
    console.log('WebSocket 连接已关闭')
  }
})
</script>