export default {
    homePage:{
        '开始赚钱计划':'Запустить план заработка',
        '快速进入':'Быстрый доступ',
        '市场':'Рынок',
        '数字货币':'Цифровая валюта',
        '涨幅':'Рост',
        '邀请朋友':'Пригласить друзей',
        '邀请朋友加入':'Пригласите друзей присоединиться',
        '开始邀请':'Начать приглашение',
        '查看':'Посмотреть',
        'Ai智能套利':'Умный арбитраж AI',
        '200家交易所的智能交易':'Умная торговля на 200 биржах',
        '矿机租赁':'Аренда майнингового оборудования',
        '效率高,回报快':'Высокая эффективность, быстрая отдача'
    },

    languagesPage:{
        '语言':'Язык',
        '确认':'Подтвердить'
    },

    noticeRecord:{
        '通知':'Уведомление',
        '消息':'Сообщение',
        '无数据':'Нет данных',
    },

    leftPage:{
        '钱包':'Кошелек',
        '套利':'Арбитраж',
        '采矿':'Майнинг',
        '设置':'Настройки',
    },

    accountPage:{
        '账户':'Аккаунт',
        '立即发送加密货币':'Отправить цифровую валюту сейчас',
        '选择一个钱包来发送加密货币':'Выберите кошелек для отправки цифровой валюты',
        '选择一个钱包':'Выберите кошелек',
        '搜索':'Поиск',
        '钱包':'Кошелек',
    },

    walletPage:{
        "钱包": "Кошелек",
        "可用": "Доступно",
        "冷冻": "Заморожено",
        "接收": "Получить",
        "发送": "Отправить",
        "转换": "Конвертировать",
        "存入资金": "Внести средства",
        "退出": "Выход",
        "最大": "Макс",
        "立即发送": "Отправить сейчас",
        "从": "От",
        "到": "До",
        "费用": "Комиссия",
        "继续固定": "Продолжить фиксирование",
        "内容1": "Пожалуйста, не отправляйте другие типы активов на указанный выше адрес. Эта операция может привести к потере ваших активов. После успешной отправки узлы сети должны подтвердить получение соответствующих активов. Поэтому, когда вы завершите перевод, пожалуйста, свяжитесь с онлайн-службой поддержки для проверки получения.",
        "内容2": "Пожалуйста, не переводите средства незнакомым людям",
        "内容3": "Вы не можете обменивать напрямую между двумя криптовалютами. Сначала вам нужно конвертировать одну криптовалюту в USDT, а затем использовать USDT для обмена на любую другую криптовалюту.",
        "资金密码": "Пароль средств",
        "请输入资金密码": "Введите пароль средств",
        "未设置资金密码": "Пароль средств не установлен",
        "发送地址不能为空": "Адрес отправки не может быть пустым",
        "金额必须大于0": "Сумма должна быть больше 0"
    },

    tradingPage:{
        '交易记录':'Запись торговых операций',
        '接收':'Получить',
        '发送':'Отправить',
        '转换':'Конвертировать',
        '无数据':'Нет данных',
    },

    arbitragePage:{
        '主持工作':'Управление работой',
        '总套利':'Общий арбитраж',
        '今日收益':'Доход за сегодня',
        '托管订单':'Заказы на хранение',
        '介绍':'Введение',
        '人工智能机器人是如何工作的':'Как работает робот AI',
        '套利产品':'Продукты арбитража',
        'AI智能挖矿':'Умный майнинг AI',
        '低买高卖获利':'Купить дешево, продать дорого',
    },

    trusteeshipDetailPage:{
        '托管详细':'Детали хранения',
        '主持':'Управление',
        '终止':'Прекратить',
        '赎回':'Выкупить',
        '无数据':'Нет данных',
    },

    miningPage:{
        '你值得信任的量化投資':'Доверительные количественные инвестиции',
        '立即开户':'Открыть аккаунт сейчас',
        '总流动性矿池金额':'Общая сумма ликвидного пула',
        '质押人數':'Количество стейкеров',
        '质押':'Стейкинг',
        '质押记录':'Запись стейкинга',
        '质押收益':'Доход от стейкинга',
        '输入要质押的USDT数量':'Введите количество USDT для стейкинга',
        '质押总量':'Общее количество стейкинга',
        '最大限度':'Максимум',
        '余额':'Баланс',
        '钱包余额':'Баланс кошелька',
        '收益率':'Доходность',
        '选择质押的期限':'Выберите срок стейкинга',
        '没有记录':'Нет записей',
        '挖矿收益地址':'Адрес доходов от майнинга',
        '数量':'Количество',
        '相关问题':'Связанные вопросы',
        '什麼是AI量化交易':'Что такое количественная торговля AI',
        '文本1':'Количественная торговля — это подход, который акцентирует внимание на обучении и статистическом анализе для определения стоимости финансовых активов (например, акций, опционов, криптовалют). Количественные трейдеры используют различные данные — включая исторические инвестиции и данные фондового рынка — для разработки торговых алгоритмов и компьютерных моделей. Информация, полученная от этих компьютерных моделей, помогает инвесторам анализировать инвестиционные возможности и разрабатывать успешные торговые стратегии. Обычно эта торговая стратегия включает в себя очень конкретную информацию о точках входа и выхода, ожидаемых рисках и предполагаемой прибыли. Конечная цель финансового количественного анализа — использовать измеримые статистические данные и метрики для помощи инвесторам в принятии прибыльных инвестиционных решений.',
        '为什么要支付手续费':'Почему нужно платить комиссию',
        '文本2':'Поскольку вам нужно открыть аккаунт для инвестиционного стейкинга в количественной торговле, ETH взимается в качестве вашей комиссии за открытие аккаунта.',
        'AI量化交易的优势':'Преимущества количественной торговли AI',
        '文本3_1':'Отсутствие человеческого вмешательства',
        '文本3_2':'Количественная торговля AI уменьшает когнитивные ошибки',
        '文本3_3':'Стратегии количественной торговли AI удваивают результаты',
        '文本3_4':'Количественные стратегии AI позволяют проводить бэктестинг',
        '文本3_5':'Можно реализовать неограниченное количество стратегий',
        '文本3_6':'Автоматическая торговля освобождает время инвесторов',
        '文本3_7':'Системы количественной торговли выполняют и тестируют одно и то же',
        '文本3_8':'Система заставляет вас следовать вашему плану',
        '在哪里进行AI量化交易':'Где проводится количественная торговля AI',
        '文本4':'Количественная торговля, разработанная совместно с Coinbase, все программы работают в кошельке Coinbase, это открытый и справедливый проект торговли AI.',
        '为什么会有这项目':'Почему существует этот проект',
        '文本5':'В 2022 году мы официально объединились с Coinbase для создания нового экосистемного консенсуса AI+ финансов, инвестировав вместе 2 миллиарда долларов США и запустив платформу количественной торговли. Достигнуто идеальное объединение AI+ финансов, с основным продуктом в виде чипов анализа и вычислений AI, установленным на нашем новейшем роботе AI 6.2.1, который ломает и переворачивает традиции различных отраслей, включая финансовую торговлю. Использование платформы количественной торговли упрощает торговлю. В следующем шаге, как платформа для торговли криптоактивами нового поколения, мы продолжим продвигаться вперед и выпустить версию Web3.0.',
        '当前总质押':'Текущий общий стейкинг',
        '当日收益':'Доход за день',
        '总收益':'Общий доход',
    },

    inviteFriendsPage:{
        '邀请朋友':'Пригласить друзей',
        '邀请朋友获得推荐奖励':'Пригласите друзей, чтобы получить вознаграждение за рекомендации',
        '好友参与即可获得推荐奖励':'Друзья, которые участвуют в арбитраже AI или аренде майнингового оборудования, получат вознаграждение за рекомендации',
        '您的邀请成功':'Ваше приглашение успешно',
        '全部':'Все',
        '直接':'Прямо',
        '间接':'Косвенно',
        '更多':'Больше',
        '直接收益':'Прямой',
        '间接收益':'Косвенный',
        '无数据':'Нет данных',
        '复制成功':'Копирование успешно',
    },

    coinDetailPage:{
        '交割合约':'Фьючерсный контракт',
        '最低价':'Минимальная цена',
        '成交量':'Объем торгов',
        '最高价':'Максимальная цена',
        '成交额':'Объем сделки',
        '时间':'Время',
        '更多':'Больше',
        '委托订单':'Заказы',
        '最新交易':'Последние сделки',
        '暂无数据':'Нет данных',
        '记录':'Запись',
        '买涨':'Купить на повышение',
        '买跌':'Купить на понижение',
        '交割':'Доставка',
        '交割时间':'Время доставки',
        '投资金额':'Сумма инвестиций',
        '金额':'Сумма',
        '余额':'Баланс',
        '预期收益':'Ожидаемая прибыль',
        '确认下单':'Подтвердите заказ',
        '请输入投资金额':'Введите сумму инвестиций',
        '确认要下单吗':'Вы уверены, что хотите сделать заказ?',
        '余额不足':'Недостаточно средств',
    },

    contractsOrderPage:{
        '委托订单':'Заказы',
        '等待中':'Ожидание',
        '已结束':'Завершено',
        '无数据':'Нет данных',
    },

    numKeyboard:{
        '完成':'Завершить',
        '输入金额不能超过可用金额':'Введенная сумма не может превышать доступную сумму',
    },

    settingPage:{
        "设置": "Настройки",
        "修改资金密码": "Изменить пароль средств",
        "旧密码": "Старый пароль",
        "请输入旧密码": "Введите старый пароль",
        "新密码": "Новый пароль",
        "请输入新密码": "Введите новый пароль",
        "确认密码": "Подтвердите пароль",
        "请再次输入新密码": "Введите новый пароль еще раз",
        "密码不能为空": "Пароль не может быть пустым",
        "新密码和确认密码不匹配": "Новый пароль и подтверждение пароля не совпадают",
        "取消": "Отмена",
        "确认": "Подтвердить",
        "设置资金密码": "Установить пароль средств",
        "资金密码": "Пароль средств"
    },

    interfaceJs: {
        "获取失败": "Не удалось получить",
        "登录失败": "Неудачный вход",
        "设置成功": "Настройки успешно сохранены",
        "修改成功": "Изменение успешно",
        "添加失败": "Не удалось добавить",
        "修改失败": "Не удалось изменить"
    },

    "时间": "Время",
    "数量": "Количество",
    "状态": "Статус",
    "审核中": "На рассмотрении",
    "已完成": "Завершено",
    "审核未通过": "Проверка не пройдена",
    "购买金额": "Сумма покупки",
    "全部": "Все",
    "输入金额": "Введите сумму",
    "可用余额": "Доступный баланс",
    "大于最大可投": "Больше максимальной инвестиции",
    "购买": "Купить",
    "购买成功": "Покупка успешна",
    "请输入购买金额": "Пожалуйста, введите сумму покупки",
    "订单号": "Номер заказа",
    "购买日期": "Дата покупки",
    "结束日期": "Дата окончания",
    "预计收益": "Ожидаемая прибыль",
    "日收益率": "Дневная доходность",
    "取消托管": "Отменить хранение",
    "接收金额": "Полученная сумма",
    "请填写金额": "Пожалуйста, введите сумму",
    "上传充值凭证": "Загрузите подтверждение пополнения",
    "充值": "Пополнить",
    "请上传图片": "Пожалуйста, загрузите изображение",
    "请输入金额": "Пожалуйста, введите сумму",
    "确认": "Подтвердить",
    "产品": "Продукт",
    "购买数量": "Количество покупки",
    "购买方向": "Направление покупки",
    "开仓时间": "Время открытия",
    "平仓时间": "Время закрытия",
    "盈亏": "Прибыль и убыток",
    "取消": "Отменить",
    "质押数量": "Количество залога",
    "期限": "Срок",
    "开始时间": "Время начала",
    "结束时间": "Время окончания",
    "收益率": "Доходность",
    "天": "Дни",
    "质押金额必须大于0": "Сумма залога должна быть больше 0",
    "请选择质押产品": "Пожалуйста, выберите продукт залога",
    "选择质押的产品": "Выберите Заложенный Продукт",
    "限额": "Лимит",
    "周期": "Цикл",
    "预警收益": "Предупреждающая Прибыль",
    "数量限制": "Ограничение Количества",
    "最少可投": "Минимальная Инвестиция",
    "最大可投": "Максимальная Инвестиция",
    "立即租用": "Арендовать Сейчас",
    "详情": "Детали",
    "收益金额": "Сумма дохода"
}
