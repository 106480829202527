export function refresh(){
    var elementById = document.getElementById("loading-loader");
    elementById.style.display = "block";
    setTimeout(()=>{
        elementById.style.display = "none";
    },500)
}

export function connectionWebSocket(url){
    const socket = new WebSocket(url);
    socket.onopen=()=>{
        console.log("WebSocket 连接成功")
    }
    return socket;
}

//根据时间戳获取年月日 时分秒
export function getTime(timestamp){
    const date = new Date(timestamp * 1000);

// 提取年月日，时分秒
    const year = String(date.getFullYear()).slice(-2); // 取年份的最后两位
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以需要 +1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
}

//通过年份与月份获取月初和月底的时间戳
export function getTimestamp(year,month){
// 月初时间戳（毫秒）并转换为秒
    const startOfMonthInSeconds = Math.floor(new Date(year, month, 1).getTime() / 1000);

    // 月末时间戳（毫秒）并转换为秒
    const endOfMonthInSeconds = Math.floor(new Date(year, month + 1, 0).getTime() / 1000);

    return {startOfMonthInSeconds,endOfMonthInSeconds};
}

export function getMonth(str){
    let month = 0;
    switch (str){
        case '01' :
            month = 0;break;
        case '02' :
            month = 1;break;
        case '03' :
            month = 2;break;
        case '04' :
            month = 3;break;
        case '05' :
            month = 4;break;
        case '06' :
            month = 5;break;
        case '07' :
            month = 6;break;
        case '08' :
            month = 7;break;
        case '09' :
            month = 8;break;
        case '10' :
            month = 9;break;
        case '11' :
            month = 10;break;
        case '12' :
            month = 11;
    }
    return month;
}