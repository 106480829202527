export default {
    homePage:{
        '开始赚钱计划':'Bắt đầu kế hoạch kiếm tiền',
        '快速进入':'Truy cập nhanh',
        '市场':'Thị trường',
        '数字货币':'Tiền điện tử',
        '涨幅':'Tăng trưởng',
        '邀请朋友':'Mời bạn bè',
        '邀请朋友加入':'Mời bạn bè tham gia',
        '开始邀请':'Bắt đầu mời',
        '查看':'Xem',
        'Ai智能套利':'Arbitrage thông minh AI',
        '200家交易所的智能交易':'Giao dịch thông minh trên 200 sàn',
        '矿机租赁':'Thuê máy đào',
        '效率高,回报快':'Hiệu quả cao, lợi nhuận nhanh'
    },

    languagesPage:{
        '语言':'Ngôn ngữ',
        '确认':'Xác nhận'
    },

    noticeRecord:{
        '通知':'Thông báo',
        '消息':'Tin nhắn',
        '无数据':'Không có dữ liệu',
    },

    leftPage:{
        '钱包':'Ví',
        '套利':'Arbitrage',
        '采矿':'Đào',
        '设置':'Cài đặt',
    },

    accountPage:{
        '账户':'Tài khoản',
        '立即发送加密货币':'Gửi tiền điện tử ngay lập tức',
        '选择一个钱包来发送加密货币':'Chọn một ví để gửi tiền điện tử',
        '选择一个钱包':'Chọn một ví',
        '搜索':'Tìm kiếm',
        '钱包':'Ví',
    },

    walletPage:{
        "钱包": "Ví",
        "可用": "Có sẵn",
        "冷冻": "Đóng băng",
        "接收": "Nhận",
        "发送": "Gửi",
        "转换": "Chuyển đổi",
        "存入资金": "Nạp tiền",
        "退出": "Đăng xuất",
        "最大": "Tối đa",
        "立即发送": "Gửi ngay",
        "从": "Từ",
        "到": "Đến",
        "费用": "Phí",
        "继续固定": "Tiếp tục cố định",
        "内容1": "Xin đừng gửi các loại tài sản khác đến địa chỉ trên. Hành động này có thể dẫn đến mất tài sản của bạn. Sau khi gửi thành công, các nút mạng cần xác nhận việc nhận tài sản tương ứng. Do đó, khi hoàn tất chuyển tiền, hãy liên hệ với dịch vụ khách hàng trực tuyến để xác nhận việc nhận hàng.",
        "内容2": "Xin đừng chuyển tiền cho người lạ",
        "内容3": "Bạn không thể giao dịch trực tiếp giữa hai loại tiền điện tử. Bạn nên trước tiên chuyển đổi một loại tiền điện tử sang USDT và sau đó sử dụng USDT để đổi sang bất kỳ loại tiền điện tử khác.",
        "资金密码": "Mật khẩu quỹ",
        "请输入资金密码": "Xin vui lòng nhập mật khẩu quỹ",
        "未设置资金密码": "Mật khẩu quỹ chưa được đặt",
        "发送地址不能为空": "Địa chỉ gửi không thể để trống",
        "金额必须大于0": "Số tiền phải lớn hơn 0"
    },

    tradingPage:{
        '交易记录':'Lịch sử giao dịch',
        '接收':'Nhận',
        '发送':'Gửi',
        '转换':'Chuyển đổi',
        '无数据':'Không có dữ liệu',
    },

    arbitragePage:{
        '主持工作':'Quản lý',
        '总套利':'Arbitrage tổng cộng',
        '今日收益':'Lợi nhuận hôm nay',
        '托管订单':'Đơn hàng gửi',
        '介绍':'Giới thiệu',
        '人工智能机器人是如何工作的':'Cách hoạt động của robot AI',
        '套利产品':'Sản phẩm arbitrage',
        'AI智能挖矿':'Đào thông minh AI',
        '低买高卖获利':'Mua thấp bán cao để có lợi nhuận',
    },

    trusteeshipDetailPage:{
        '托管详细':'Chi tiết gửi',
        '主持':'Quản lý',
        '终止':'Kết thúc',
        '赎回':'Rút tiền',
        '无数据':'Không có dữ liệu',
    },

    miningPage:{
        '你值得信任的量化投資':'Đầu tư định lượng mà bạn có thể tin tưởng',
        '立即开户':'Mở tài khoản ngay lập tức',
        '总流动性矿池金额':'Tổng số tiền trong hồ bơi thanh khoản',
        '质押人數':'Số lượng người staking',
        '质押':'Staking',
        '质押记录':'Lịch sử staking',
        '质押收益':'Lợi nhuận từ staking',
        '输入要质押的USDT数量':'Nhập số lượng USDT để staking',
        '质押总量':'Tổng số lượng staking',
        '最大限度':'Giới hạn tối đa',
        '余额':'Số dư',
        '钱包余额':'Số dư ví',
        '收益率':'Tỷ lệ lợi nhuận',
        '选择质押的期限':'Chọn thời gian staking',
        '没有记录':'Không có ghi chép',
        '挖矿收益地址':'Địa chỉ lợi nhuận đào',
        '数量':'Số lượng',
        '相关问题':'Câu hỏi liên quan',
        '什麼是AI量化交易':'Giao dịch định lượng AI là gì',
        '文本1':'Giao dịch định lượng tài chính là phương pháp tập trung vào việc dạy và phân tích thống kê để giúp xác định giá trị của các tài sản tài chính (ví dụ như cổ phiếu hoặc tùy chọn, tiền điện tử). Các nhà giao dịch định lượng sử dụng dữ liệu khác nhau — bao gồm dữ liệu đầu tư lịch sử và dữ liệu thị trường chứng khoán — để phát triển các thuật toán giao dịch và mô hình máy tính. Thông tin từ các mô hình máy tính này giúp nhà đầu tư phân tích cơ hội đầu tư và xây dựng chiến lược giao dịch thành công. Thông thường, chiến lược giao dịch này sẽ bao gồm thông tin rất cụ thể về điểm vào và điểm ra, rủi ro dự kiến và lợi nhuận dự kiến. Mục tiêu cuối cùng của phân tích định lượng tài chính là sử dụng dữ liệu và chỉ số có thể đo lường được để giúp nhà đầu tư đưa ra quyết định đầu tư có lợi nhuận.',
        '为什么要支付手续费':'Tại sao phải trả phí',
        '文本2':'Do bạn cần mở tài khoản để đầu tư staking trong giao dịch định lượng, phí ETH được thu là phí mở tài khoản của bạn.',
        'AI量化交易的优势':'Lợi ích của giao dịch định lượng AI',
        '文本3_1':'Không cần can thiệp của con người',
        '文本3_2':'Giao dịch định lượng AI giảm lỗi nhận thức',
        '文本3_3':'Chiến lược giao dịch định lượng AI tăng gấp đôi hiệu quả',
        '文本3_4':'Chiến lược định lượng AI cho phép kiểm tra lại',
        '文本3_5':'Có thể triển khai số lượng chiến lược không giới hạn',
        '文本3_6':'Giao dịch tự động giải phóng thời gian của nhà đầu tư',
        '文本3_7':'Hệ thống giao dịch định lượng thực hiện và kiểm tra giống nhau',
        '文本3_8':'Hệ thống giúp bạn tuân theo kế hoạch của mình',
        '在哪里进行AI量化交易':'Giao dịch định lượng AI được thực hiện ở đâu',
        '文本4':'Giao dịch định lượng phát triển cùng với Coinbase, tất cả các chương trình hoạt động trong ví Coinbase, là một dự án giao dịch AI công khai và công bằng',
        '为什么会有这项目':'Tại sao có dự án này',
        '文本5':'Vào năm 2022, chúng tôi đã hợp tác chính thức với Coinbase để xây dựng hệ sinh thái AI+ tài chính mới, cùng đầu tư 2 tỷ đô la và ra mắt nền tảng giao dịch định lượng. Sự kết hợp hoàn hảo của AI+ tài chính, sản phẩm cốt lõi là chip phân tích và tính toán AI, được trang bị trên robot AI mới nhất của chúng tôi 6.2.1. Sự ra mắt này phá vỡ và thay đổi nhiều ngành công nghiệp truyền thống, bao gồm cả truyền thống giao dịch tài chính. Sử dụng nền tảng giao dịch định lượng khiến giao dịch trở nên dễ dàng hơn. Bước tiếp theo, với tư cách là nền tảng giao dịch tài sản kỹ thuật số thế hệ mới, sẽ tiếp tục phát triển mạnh mẽ và ra mắt phiên bản Web3.0.',
        '当前总质押':'Tổng số lượng staking hiện tại',
        '当日收益':'Lợi nhuận hôm nay',
        '总收益':'Lợi nhuận tổng cộng',
    },

    inviteFriendsPage:{
        '邀请朋友':'Mời bạn bè',
        '邀请朋友获得推荐奖励':'Mời bạn bè nhận phần thưởng giới thiệu',
        '好友参与即可获得推荐奖励':'Bạn bè tham gia giao dịch arbitrage AI, thuê máy đào sẽ nhận phần thưởng giới thiệu',
        '您的邀请成功':'Lời mời của bạn đã thành công',
        '全部':'Tất cả',
        '直接':'Trực tiếp',
        '间接':'Gián tiếp',
        '更多':'Thêm',
        '直接收益':'Thu nhập trực tiếp',
        '间接收益':'Thu nhập gián tiếp',
        '无数据':'Không có dữ liệu',
        '复制成功':'Sao chép thành công',
    },

    coinDetailPage:{
        '交割合约':'Hợp đồng giao hàng',
        '最低价':'Giá thấp nhất',
        '成交量':'Khối lượng giao dịch',
        '最高价':'Giá cao nhất',
        '成交额':'Giá trị giao dịch',
        '时间':'Thời gian',
        '更多':'Thêm',
        '委托订单':'Đơn hàng đặt',
        '最新交易':'Giao dịch gần đây',
        '暂无数据':'Không có dữ liệu',
        '记录':'Ghi chép',
        '买涨':'Mua tăng',
        '买跌':'Bán giảm',
        '交割':'Giao hàng',
        '交割时间':'Thời gian giao hàng',
        '投资金额':'Số tiền đầu tư',
        '金额':'Số tiền',
        '余额':'Số dư',
        '预期收益':'Lợi nhuận dự kiến',
        '确认下单':'Xác nhận đặt hàng',
        '请输入投资金额':'Vui lòng nhập số tiền đầu tư',
        '确认要下单吗':'Bạn có chắc chắn muốn đặt hàng không?',
        '余额不足':'Số dư không đủ',
    },

    contractsOrderPage:{
        '委托订单':'Đơn hàng đặt',
        '等待中':'Đang chờ',
        '已结束':'Đã kết thúc',
        '无数据':'Không có dữ liệu',
    },

    numKeyboard:{
        '完成':'Hoàn tất',
        '输入金额不能超过可用金额':'Số tiền nhập không thể vượt quá số tiền có sẵn',
    },

    settingPage:{
        "设置": "Cài đặt",
        "修改资金密码": "Thay đổi mật khẩu quỹ",
        "旧密码": "Mật khẩu cũ",
        "请输入旧密码": "Vui lòng nhập mật khẩu cũ",
        "新密码": "Mật khẩu mới",
        "请输入新密码": "Vui lòng nhập mật khẩu mới",
        "确认密码": "Xác nhận mật khẩu",
        "请再次输入新密码": "Vui lòng nhập lại mật khẩu mới",
        "密码不能为空": "Mật khẩu không thể để trống",
        "新密码和确认密码不匹配": "Mật khẩu mới và xác nhận mật khẩu không khớp",
        "取消": "Hủy",
        "确认": "Xác nhận",
        "设置资金密码": "Đặt mật khẩu quỹ",
        "资金密码": "Mật khẩu quỹ"
    },

    interfaceJs: {
        "获取失败": "Lấy dữ liệu thất bại",
        "登录失败": "Đăng nhập thất bại",
        "设置成功": "Cài đặt thành công",
        "修改成功": "Thay đổi thành công",
        "添加失败": "Thêm thất bại",
        "修改失败": "Thay đổi thất bại"
    },

    "时间": "Thời gian",
    "数量": "Số lượng",
    "状态": "Trạng thái",
    "审核中": "Đang xem xét",
    "已完成": "Hoàn thành",
    "审核未通过": "Xem xét không thành công",
    "购买金额": "Số tiền mua",
    "全部": "Tất cả",
    "输入金额": "Nhập số tiền",
    "可用余额": "Số dư khả dụng",
    "大于最大可投": "Lớn hơn đầu tư tối đa",
    "购买": "Mua",
    "购买成功": "Mua thành công",
    "请输入购买金额": "Vui lòng nhập số tiền mua",
    "订单号": "Số đơn hàng",
    "购买日期": "Ngày mua",
    "结束日期": "Ngày kết thúc",
    "预计收益": "Lợi nhuận dự kiến",
    "日收益率": "Tỷ lệ lợi nhuận hàng ngày",
    "取消托管": "Hủy quản lý",
    "接收金额": "Số tiền nhận được",
    "请填写金额": "Vui lòng nhập số tiền",
    "上传充值凭证": "Tải lên chứng từ nạp tiền",
    "充值": "Nạp tiền",
    "请上传图片": "Vui lòng tải lên hình ảnh",
    "请输入金额": "Vui lòng nhập số tiền",
    "确认": "Xác nhận",
    "产品": "Sản phẩm",
    "购买数量": "Số lượng mua",
    "购买方向": "Hướng mua",
    "开仓时间": "Thời gian mở vị trí",
    "平仓时间": "Thời gian đóng vị trí",
    "盈亏": "Lợi nhuận và thua lỗ",
    "取消": "Hủy",
    "质押数量": "Số lượng thế chấp",
    "期限": "Thời hạn",
    "开始时间": "Thời gian bắt đầu",
    "结束时间": "Thời gian kết thúc",
    "收益率": "Tỷ lệ lợi nhuận",
    "天": "Ngày",
    "质押金额必须大于0": "Số tiền thế chấp phải lớn hơn 0",
    "请选择质押产品": "Vui lòng chọn sản phẩm thế chấp",
    "选择质押的产品": "Chọn Sản Phẩm Thế Chấp",
    "限额": "Giới Hạn",
    "周期": "Chu Kỳ",
    "预警收益": "Lợi Nhuận Cảnh Báo",
    "数量限制": "Giới Hạn Số Lượng",
    "最少可投": "Đầu Tư Tối Thiểu",
    "最大可投": "Đầu Tư Tối Đa",
    "立即租用": "Thuê Ngay",
    "详情": "Chi tiết",
    "收益金额": "Số tiền thu nhập"
}
