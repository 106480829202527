export default {
    homePage:{
        '开始赚钱计划':'お金を稼ぐ計画を始める',
        '快速进入':'すぐにアクセス',
        '市场':'市場',
        '数字货币':'デジタル通貨',
        '涨幅':'価格の上昇',
        '邀请朋友':'友達を招待',
        '邀请朋友加入':'友達を招待して参加する',
        '开始邀请':'招待を開始する',
        '查看':'表示',
        'Ai智能套利':'AIスマートアービトラージ',
        '200家交易所的智能交易':'200の取引所のスマートトレーディング',
        '矿机租赁':'マイニング機器のレンタル',
        '效率高,回报快':'高効率、迅速なリターン'
    },

    languagesPage:{
        '语言':'言語',
        '确认':'確認'
    },

    noticeRecord:{
        '通知':'通知',
        '消息':'メッセージ',
        '无数据':'データがありません',
    },

    leftPage:{
        '钱包':'ウォレット',
        '套利':'アービトラージ',
        '采矿':'マイニング',
        '设置':'設定',
    },

    accountPage:{
        '账户':'アカウント',
        '立即发送加密货币':'暗号通貨をすぐに送信',
        '选择一个钱包来发送加密货币':'暗号通貨を送信するウォレットを選択',
        '选择一个钱包':'ウォレットを選択',
        '搜索':'検索',
        '钱包':'ウォレット',
    },

    walletPage:{
        "钱包": "ウォレット",
        "可用": "利用可能",
        "冷冻": "凍結",
        "接收": "受け取る",
        "发送": "送信",
        "转换": "変換",
        "存入资金": "資金を入金",
        "退出": "ログアウト",
        "最大": "最大",
        "立即发送": "今すぐ送信",
        "从": "から",
        "到": "まで",
        "费用": "手数料",
        "继续固定": "固定を続ける",
        "内容1": "他の種類の資産を上記のアドレスに送信しないでください。この操作により、資産が失われる可能性があります。送信が成功した後、ネットワークノードは対応する資産の受け取りを確認する必要があります。そのため、送金が完了したら、オンラインカスタマーサービスに連絡して受領を確認してください。",
        "内容2": "見知らぬ人に資金を送らないでください",
        "内容3": "2つの暗号通貨の間で直接取引することはできません。まず、1つの暗号通貨をUSDTに変換し、その後、USDTを使用して他の暗号通貨に交換してください。",
        "资金密码": "資金パスワード",
        "请输入资金密码": "資金パスワードを入力してください",
        "未设置资金密码": "資金パスワードが設定されていません",
        "发送地址不能为空": "送信先アドレスを空にすることはできません",
        "金额必须大于0": "金額は0より大きくする必要があります"
    },

    tradingPage:{
        '交易记录':'取引履歴',
        '接收':'受け取る',
        '发送':'送信',
        '转换':'変換',
        '无数据':'データがありません',
    },

    arbitragePage:{
        '主持工作':'管理作業',
        '总套利':'総アービトラージ',
        '今日收益':'本日の利益',
        '托管订单':'保管注文',
        '介绍':'紹介',
        '人工智能机器人是如何工作的':'AIロボットの動作方法',
        '套利产品':'アービトラージ商品',
        'AI智能挖矿':'AIスマートマイニング',
        '低买高卖获利':'安く買って高く売って利益を得る',
    },

    trusteeshipDetailPage:{
        '托管详细':'保管詳細',
        '主持':'管理',
        '终止':'終了',
        '赎回':'償還',
        '无数据':'データがありません',
    },

    miningPage:{
        '你值得信任的量化投資':'信頼できるクオンツ投資',
        '立即开户':'今すぐアカウントを開設',
        '总流动性矿池金额':'総流動性マイニングプール額',
        '质押人數':'ステーキング人数',
        '质押':'ステーキング',
        '质押记录':'ステーキング記録',
        '质押收益':'ステーキング収益',
        '输入要质押的USDT数量':'ステーキングするUSDTの数量を入力',
        '质押总量':'ステーキング総量',
        '最大限度':'最大限度',
        '余额':'残高',
        '钱包余额':'ウォレット残高',
        '收益率':'収益率',
        '选择质押的期限':'ステーキングの期間を選択',
        '没有记录':'記録がありません',
        '挖矿收益地址':'マイニング収益アドレス',
        '数量':'数量',
        '相关问题':'関連する質問',
        '什麼是AI量化交易':'AIクオンツ取引とは',
        '文本1':'クオンツ取引とは、金融資産（例えば株式やオプション、暗号通貨）の価値を特定するために教育と統計分析を重視する方法です。クオンツトレーダーは、歴史的な投資や株式市場データを含むさまざまなデータを使用して、取引アルゴリズムとコンピューターモデルを開発します。これらのコンピューターモデルから得られる情報は、投資機会の分析や成功する取引戦略の策定に役立ちます。通常、この取引戦略には、エントリーおよびエグジットポイント、取引の期待リスクと期待リターンに関する非常に具体的な情報が含まれます。クオンツ分析の最終的な目標は、測定可能な統計データと指標を使用して、投資家が利益を得るための投資決定を下す手助けをすることです。',
        '为什么要支付手续费':'なぜ手数料を支払う必要があるのか',
        '文本2':'クオンツ取引でステーキング投資を行うためにアカウントを開設する必要があるため、ETHがアカウント開設手数料として徴収されます。',
        'AI量化交易的优势':'AIクオンツ取引の利点',
        '文本3_1':'手動介入不要',
        '文本3_2':'AIクオンツ取引は認知エラーを減らす',
        '文本3_3':'AIクオンツ取引戦略で効果が倍増',
        '文本3_4':'AIクオンツ戦略はバックテストを許可',
        '文本3_5':'無限の戦略を実行可能',
        '文本3_6':'自動取引で投資家の時間を解放',
        '文本3_7':'クオンツ取引システムはバックテストと一致する',
        '文本3_8':'システムが計画に従わせる',
        '在哪里进行AI量化交易':'どこでAIクオンツ取引を行うか',
        '文本4':'Coinbaseと共同で開発されたクオンツ取引は、すべてのプログラムがCoinbaseウォレットで実行される、公開かつ公正なAIクオンツ取引プロジェクトです。',
        '为什么会有这项目':'なぜこのプロジェクトがあるのか',
        '文本5':'2022年、Coinbaseと正式に提携し、新しいAI+金融エコシステムコンセンサスサークルの構築に取り組み、Coinbaseと共同で20億ドルを投資して、クオンツ取引プラットフォームを開発しました。AIと金融の完璧な統合を実現し、核心製品はAI分析計算チップであり、これが最新の6.2.1 AIロボットに搭載され、その登場が複数の業界の伝統を破り、金融取引の伝統も含まれます。クオンツ取引プラットフォームを使用することで、取引がより簡単になります。次のステップとして、新世代の暗号資産取引プラットフォームがさらに力を入れ、Web3.0バージョンを強力に導入します。',
        '当前总质押':'現在の総ステーキング額',
        '当日收益':'当日収益',
        '总收益':'総収益',
    },

    inviteFriendsPage:{
        '邀请朋友':'友達を招待',
        '邀请朋友获得推荐奖励':'友達を招待して推薦報酬を獲得',
        '好友参与即可获得推荐奖励':'友達がAIアービトラージやマイニング機器レンタルに参加すると推薦報酬が得られます',
        '您的邀请成功':'招待が成功しました',
        '全部':'すべて',
        '直接':'直接',
        '间接':'間接',
        '更多':'もっと',
        '直接收益':'直接収益',
        '间接收益':'間接収益',
        '无数据':'データがありません',
        '复制成功':'コピー成功',
    },

    coinDetailPage:{
        '交割合约':'デリバティブ契約',
        '最低价':'最低価格',
        '成交量':'取引量',
        '最高价':'最高価格',
        '成交额':'取引額',
        '时间':'時間',
        '更多':'もっと',
        '委托订单':'委託注文',
        '最新交易':'最新取引',
        '暂无数据':'データがありません',
        '记录':'記録',
        '买涨':'買い上げ',
        '买跌':'売り下げ',
        '交割':'決済',
        '交割时间':'決済時間',
        '投资金额':'投資額',
        '金额':'金額',
        '余额':'残高',
        '预期收益':'予想収益',
        '确认下单':'注文を確認',
        '请输入投资金额':'投資額を入力してください',
        '确认要下单吗':'注文を確定しますか？',
        '余额不足':'残高不足',
    },

    contractsOrderPage:{
        '委托订单':'委託注文',
        '等待中':'待機中',
        '已結束':'終了済み',
        '无数据':'データがありません',
    },

    numKeyboard:{
        '完成':'完了',
        '输入金额不能超过可用金额':'入力金額は利用可能額を超えてはいけません',
    },

    settingPage:{
        "设置": "設定",
        "修改资金密码": "資金パスワードを変更",
        "旧密码": "旧パスワード",
        "请输入旧密码": "旧パスワードを入力してください",
        "新密码": "新しいパスワード",
        "请输入新密码": "新しいパスワードを入力してください",
        "确认密码": "パスワードの確認",
        "请再次输入新密码": "新しいパスワードを再度入力してください",
        "密码不能为空": "パスワードは空にできません",
        "新密码和确认密码不匹配": "新しいパスワードと確認パスワードが一致しません",
        "取消": "キャンセル",
        "确认": "確認",
        "设置资金密码": "資金パスワードを設定",
        "资金密码": "資金パスワード"
    },

    interfaceJs: {
        "获取失败": "取得に失敗しました",
        "登录失败": "ログインに失敗しました",
        "设置成功": "設定が成功しました",
        "修改成功": "変更が成功しました",
        "添加失败": "追加に失敗しました",
        "修改失败": "変更に失敗しました"
    },

    "时间": "時間",
    "数量": "数量",
    "状态": "状態",
    "审核中": "審査中",
    "已完成": "完了",
    "审核未通过": "審査失敗",
    "购买金额": "購入金額",
    "全部": "すべて",
    "输入金额": "金額を入力",
    "可用余额": "利用可能残高",
    "大于最大可投": "最大投資額を超えています",
    "购买": "購入",
    "购买成功": "購入成功",
    "请输入购买金额": "購入金額を入力してください",
    "订单号": "注文番号",
    "购买日期": "購入日",
    "结束日期": "終了日",
    "预计收益": "予想利益",
    "日收益率": "日次利回り",
    "取消托管": "保管をキャンセル",
    "接收金额": "受け取った金額",
    "请填写金额": "金額を入力してください",
    "上传充值凭证": "入金証明書をアップロード",
    "充值": "チャージ",
    "请上传图片": "画像をアップロードしてください",
    "请输入金额": "金額を入力してください",
    "确认": "確認",
    "产品": "製品",
    "购买数量": "購入数量",
    "购买方向": "購入方向",
    "开仓时间": "開店時間",
    "平仓时间": "閉店時間",
    "盈亏": "利益と損失",
    "取消": "キャンセル",
    "质押数量": "担保数量",
    "期限": "期限",
    "开始时间": "開始時間",
    "结束时间": "終了時間",
    "收益率": "利回り",
    "天": "日",
    "质押金额必须大于0": "担保額は0より大きくなければなりません",
    "请选择质押产品": "担保商品を選択してください",
    "选择质押的产品": "担保商品を選択",
    "限额": "制限",
    "周期": "サイクル",
    "预警收益": "警告利益",
    "数量限制": "数量制限",
    "最少可投": "最小投資額",
    "最大可投": "最大投資額",
    "立即租用": "今すぐレンタル",
    "详情": "詳細",
    "收益金额": "収益額"
}
