export default {
    homePage:{
        '开始赚钱计划':'Mulakan Rancangan Menjana Wang',
        '快速进入':'Akses Pantas',
        '市场':'Pasaran',
        '数字货币':'Mata Wang Digital',
        '涨幅':'Kenaikan',
        '邀请朋友':'Jemput Kawan',
        '邀请朋友加入':'Jemput Kawan untuk Sertai',
        '开始邀请':'Mulakan Jemputan',
        '查看':'Lihat',
        'Ai智能套利':'Arbitraj Pintar AI',
        '200家交易所的智能交易':'Perdagangan Pintar dari 200 Borsa',
        '矿机租赁':'Sewa Mesin Perlombongan',
        '效率高,回报快':'Berkesan Tinggi, Pulangan Cepat'
    },

    languagesPage:{
        '语言':'Bahasa',
        '确认':'Sahkan'
    },

    noticeRecord:{
        '通知':'Notifikasi',
        '消息':'Mesej',
        '无数据':'Tiada Data',
    },

    leftPage:{
        '钱包':'Dompet',
        '套利':'Arbitraj',
        '采矿':'Perlombongan',
        '设置':'Tetapan',
    },

    accountPage:{
        '账户':'Akaun',
        '立即发送加密货币':'Hantar Mata Wang Digital Sekarang',
        '选择一个钱包来发送加密货币':'Pilih Dompet untuk Hantar Mata Wang Digital',
        '选择一个钱包':'Pilih Dompet',
        '搜索':'Carian',
        '钱包':'Dompet',
    },

    walletPage:{
        "钱包": "Dompet",
        "可用": "Tersedia",
        "冷冻": "Beku",
        "接收": "Terima",
        "发送": "Hantar",
        "转换": "Tukar",
        "存入资金": "Depositkan Dana",
        "退出": "Log Keluar",
        "最大": "Maks",
        "立即发送": "Hantar Sekarang",
        "从": "Dari",
        "到": "Ke",
        "费用": "Yuran",
        "继续固定": "Teruskan Tetap",
        "内容1": "Sila jangan hantar jenis aset lain ke alamat di atas. Tindakan ini boleh menyebabkan kehilangan aset anda. Setelah penghantaran berjaya, nod rangkaian perlu mengesahkan penerimaan aset yang sesuai. Oleh itu, sila hubungi khidmat pelanggan dalam talian tepat pada masanya untuk mengesahkan penerimaan apabila anda selesai pemindahan.",
        "内容2": "Sila jangan pindahkan dana kepada orang yang tidak dikenali",
        "内容3": "Anda tidak boleh berdagang terus antara dua mata wang kripto. Anda perlu terlebih dahulu menukar satu mata wang kripto kepada USDT, dan kemudian gunakan USDT untuk menukar kepada mana-mana mata wang kripto lain.",
        "资金密码": "Kata Laluan Dana",
        "请输入资金密码": "Sila masukkan kata laluan dana",
        "未设置资金密码": "Kata laluan dana belum ditetapkan",
        "发送地址不能为空": "Alamat penghantaran tidak boleh kosong",
        "金额必须大于0": "Jumlah mesti lebih daripada 0"
    },

    tradingPage:{
        '交易记录':'Rekod Dagangan',
        '接收':'Terima',
        '发送':'Hantar',
        '转换':'Tukar',
        '无数据':'Tiada Data',
    },

    arbitragePage:{
        '主持工作':'Pengurusan Kerja',
        '总套利':'Jumlah Arbitraj',
        '今日收益':'Pendapatan Hari Ini',
        '托管订单':'Pesanan Penjagaan',
        '介绍':'Pengenalan',
        '人工智能机器人是如何工作的':'Bagaimana Robot AI Berfungsi',
        '套利产品':'Produk Arbitraj',
        'AI智能挖矿':'Perlombongan Pintar AI',
        '低买高卖获利':'Beli Murah Jual Mahal',
    },

    trusteeshipDetailPage:{
        '托管详细':'Butiran Penjagaan',
        '主持':'Pengurusan',
        '终止':'Hentikan',
        '赎回':'Tebus',
        '无数据':'Tiada Data',
    },

    miningPage:{
        '你值得信任的量化投資':'Pelaburan Kuantitatif yang Dipercayai',
        '立即开户':'Buka Akaun Sekarang',
        '总流动性矿池金额':'Jumlah Kolam Likuiditi',
        '质押人數':'Bilangan Staker',
        '质押':'Staking',
        '质押记录':'Rekod Staking',
        '质押收益':'Pendapatan Staking',
        '输入要质押的USDT数量':'Masukkan Kuantiti USDT untuk Staking',
        '质押总量':'Jumlah Staking',
        '最大限度':'Had Maksimum',
        '余额':'Baki',
        '钱包余额':'Baki Dompet',
        '收益率':'Kadar Pulangan',
        '选择质押的期限':'Pilih Tempoh Staking',
        '没有记录':'Tiada Rekod',
        '挖矿收益地址':'Alamat Pendapatan Perlombongan',
        '数量':'Kuantiti',
        '相关问题':'Soalan Berkaitan',
        '什麼是AI量化交易':'Apa Itu Dagangan Kuantitatif AI',
        '文本1':'Dagangan kuantitatif adalah pendekatan yang menekankan pengajaran dan analisis statistik untuk membantu menentukan nilai aset kewangan (contohnya saham, opsyen, mata wang digital). Pedagang kuantitatif menggunakan pelbagai data – termasuk data pelaburan sejarah dan pasaran saham – untuk membangunkan algoritma dagangan dan model komputer. Maklumat yang dihasilkan oleh model komputer ini membantu pelabur menganalisis peluang pelaburan dan merangka strategi dagangan yang berjaya. Biasanya, strategi dagangan ini akan merangkumi maklumat yang sangat spesifik tentang titik masuk dan keluar, risiko yang dijangka dan pulangan yang dijangka. Matlamat akhir analisis kewangan kuantitatif adalah untuk menggunakan data dan metrik statistik yang boleh diukur untuk membantu pelabur membuat keputusan pelaburan yang menguntungkan.',
        '为什么要支付手续费':'Mengapa Perlu Bayar Yuran',
        '文本2':'Anda perlu membuka akaun untuk pelaburan staking dalam dagangan kuantitatif, jadi ETH dikenakan sebagai yuran pembukaan akaun anda.',
        'AI量化交易的优势':'Kelebihan Dagangan Kuantitatif AI',
        '文本3_1':'Tiada campur tangan manusia',
        '文本3_2':'Dagangan kuantitatif AI mengurangkan kesilapan kognitif',
        '文本3_3':'Strategi dagangan kuantitatif AI menggandakan hasil',
        '文本3_4':'Strategi kuantitatif AI membenarkan ujian balik',
        '文本3_5':'Boleh melaksanakan bilangan strategi yang tidak terhad',
        '文本3_6':'Dagangan automatik membebaskan masa pelabur',
        '文本3_7':'Sistem dagangan kuantitatif melaksanakan dan menguji sama',
        '文本3_8':'Sistem memaksa anda mengikuti rancangan anda',
        '在哪里进行AI量化交易':'Di Mana Dagangan Kuantitatif AI Dilakukan',
        '文本4':'Dagangan kuantitatif yang dibangunkan bersama Coinbase, semua program dijalankan dalam dompet Coinbase, merupakan projek dagangan kuantitatif AI yang terbuka dan adil.',
        '为什么会有这项目':'Mengapa Projek Ini Wujud',
        '文本5':'Pada tahun 2022, kami secara rasmi bekerjasama dengan Coinbase untuk membangunkan ekosistem AI+ kewangan baru, melabur bersama sebanyak 2 bilion USD dan melancarkan platform dagangan kuantitatif. Penyatuan sempurna AI+ kewangan dicapai, dengan produk utama adalah cip pengiraan analisis AI yang dimuatkan pada robot AI terbaru 6.2.1, yang memecahkan dan mengubah tradisi pelbagai industri termasuk tradisi dagangan kewangan. Menggunakan platform dagangan kuantitatif menjadikannya lebih mudah. Langkah seterusnya, sebagai platform perdagangan aset kripto generasi baharu, akan terus memacu kuasa dan melancarkan versi Web3.0 dengan kuat.',
        '当前总质押':'Jumlah Staking Semasa',
        '当日收益':'Pendapatan Hari Ini',
        '总收益':'Jumlah Pendapatan',
    },

    inviteFriendsPage:{
        '邀请朋友':'Jemput Kawan',
        '邀请朋友获得推荐奖励':'Jemput Kawan untuk Mendapatkan Ganjaran Rujukan',
        '好友参与即可获得推荐奖励':'Kawan yang menyertai AI Arbitraj, sewa mesin perlombongan akan mendapat ganjaran rujukan',
        '您的邀请成功':'Jemputan Anda Berjaya',
        '全部':'Semua',
        '直接':'Langsung',
        '间接':'Tidak Langsung',
        '更多':'Lebih Banyak',
        '直接收益':'Langsung',
        '间接收益':'Tidak Langsung',
        '无数据':'Tiada Data',
        '复制成功':'Salinan Berjaya',
    },

    coinDetailPage:{
        '交割合约':'Kontrak Hadapan',
        '最低价':'Harga Terendah',
        '成交量':'Jumlah Dagangan',
        '最高价':'Harga Tertinggi',
        '成交额':'Jumlah Dagangan',
        '时间':'Masa',
        '更多':'Lebih Banyak',
        '委托订单':'Pesanan',
        '最新交易':'Dagangan Terbaru',
        '暂无数据':'Tiada Data',
        '记录':'Rekod',
        '买涨':'Beli Tinggi',
        '买跌':'Beli Rendah',
        '交割':'Penyerahan',
        '交割时间':'Masa Penyerahan',
        '投资金额':'Jumlah Pelaburan',
        '金额':'Jumlah',
        '余额':'Baki',
        '预期收益':'Pulangan Dijangka',
        '确认下单':'Sahkan Pesanan',
        '请输入投资金额':'Masukkan Jumlah Pelaburan',
        '确认要下单吗':'Adakah Anda Pasti Mahu Pesan?',
        '余额不足':'Baki Tidak Mencukupi',
    },

    contractsOrderPage:{
        '委托订单':'Pesanan',
        '等待中':'Menunggu',
        '已结束':'Telah Tamat',
        '无数据':'Tiada Data',
    },

    numKeyboard:{
        '完成':'Selesai',
        '输入金额不能超过可用金额':'Jumlah yang dimasukkan tidak boleh melebihi jumlah yang boleh digunakan',
    },

    settingPage:{
        "设置": "Tetapan",
        "修改资金密码": "Tukar Kata Laluan Dana",
        "旧密码": "Kata Laluan Lama",
        "请输入旧密码": "Sila masukkan kata laluan lama",
        "新密码": "Kata Laluan Baru",
        "请输入新密码": "Sila masukkan kata laluan baru",
        "确认密码": "Sahkan Kata Laluan",
        "请再次输入新密码": "Sila masukkan kata laluan baru sekali lagi",
        "密码不能为空": "Kata laluan tidak boleh kosong",
        "新密码和确认密码不匹配": "Kata laluan baru dan kata laluan pengesahan tidak sepadan",
        "取消": "Batal",
        "确认": "Sahkan",
        "设置资金密码": "Tetapkan Kata Laluan Dana",
        "资金密码": "Kata Laluan Dana"
    },

    interfaceJs: {
        "获取失败": "Gagal mengambil",
        "登录失败": "Log masuk gagal",
        "设置成功": "Tetapan berjaya disimpan",
        "修改成功": "Pengubahsuaian berjaya",
        "添加失败": "Penambahan gagal",
        "修改失败": "Pengubahsuaian gagal"
    },

    "时间": "Masa",
    "数量": "Kuantiti",
    "状态": "Status",
    "审核中": "Dalam Semakan",
    "已完成": "Selesai",
    "审核未通过": "Semakan Gagal",
    "购买金额": "Jumlah Pembelian",
    "全部": "Semua",
    "输入金额": "Masukkan Jumlah",
    "可用余额": "Baki Tersedia",
    "大于最大可投": "Lebih dari Pelaburan Maksimum",
    "购买": "Beli",
    "购买成功": "Pembelian Berjaya",
    "请输入购买金额": "Sila Masukkan Jumlah Pembelian",
    "订单号": "Nombor Pesanan",
    "购买日期": "Tarikh Pembelian",
    "结束日期": "Tarikh Tamat",
    "预计收益": "Keuntungan Dijangka",
    "日收益率": "Kadar Pulangan Harian",
    "取消托管": "Batal Penjagaan",
    "接收金额": "Jumlah Diterima",
    "请填写金额": "Sila Masukkan Jumlah",
    "上传充值凭证": "Muat Naik Bukti Pengisian",
    "充值": "Isi Semula",
    "请上传图片": "Sila Muat Naik Imej",
    "请输入金额": "Sila Masukkan Jumlah",
    "确认": "Sahkan",
    "产品": "Produk",
    "购买数量": "Kuantiti Pembelian",
    "购买方向": "Arah Pembelian",
    "开仓时间": "Masa Pembukaan",
    "平仓时间": "Masa Penutupan",
    "盈亏": "Keuntungan dan Kerugian",
    "取消": "Batal",
    "质押数量": "Kuantiti Penjamin",
    "期限": "Tempoh",
    "开始时间": "Masa Mula",
    "结束时间": "Masa Tamat",
    "收益率": "Kadar Pulangan",
    "天": "Hari",
    "质押金额必须大于0": "Jumlah Penjamin Mestilah Lebih dari 0",
    "请选择质押产品": "Sila Pilih Produk Penjamin",
    "选择质押的产品": "Pilih Produk Penjamin",
    "限额": "Had",
    "周期": "Siklus",
    "预警收益": "Keuntungan Amaran",
    "数量限制": "Had Kuantiti",
    "最少可投": "Pelaburan Minimum",
    "最大可投": "Pelaburan Maksimum",
    "立即租用": "Sewa Sekarang",
    "详情": "Butiran",
    "收益金额": "Jumlah Pendapatan"
}
