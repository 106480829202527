<template>
  <div style="height: 100%; background: linear-gradient(179.88deg, rgb(2, 30, 67) 0.11%, rgb(0, 41, 97) 99.9%); overflow: auto;">
    <div style="height: 100%">
      <div style="height: 100%">

        <div class="pages_header">
          <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
          <span class="App_header_title"> {{ $t('noticeRecord.通知') }} </span>
        </div>

        <div class="switch_container">
          <div class="switch_content">
            <div class="switch_item" @click="switchToggle('notice')" v-if="switchNotice"><span>{{ $t('noticeRecord.通知') }}</span></div>
            <div class="switch_item active" @click="switchToggle('notice_active')" v-if="switchNoticeActive"><span>{{ $t('noticeRecord.通知') }}</span></div>

            <div class="switch_item" @click="switchToggle('message')" v-if="switchMessage"><span>{{ $t('noticeRecord.消息') }}</span></div>
            <div class="switch_item active" @click="switchToggle('message_active')" v-if="switchMessageActive"><span>{{ $t('noticeRecord.消息') }}</span></div>
          </div>
        </div>

        <div class="list_container">
          <div>
            <div v-if="noticesData.length===0" class="no_data_content ff_NunitoSemiBold" style="min-height: calc(-260px + 100vh);">
              <img src="@/assets/imgs/noData-icon.png" alt="" class="img_no_data" />
              <div class="no_data_text">{{ $t('noticeRecord.无数据') }}</div>
            </div>

            <div v-if="noticesData.length!==0" class="data_content ff_NunitoSemiBold" style="min-height: calc(-260px + 100vh);text-align: left">
              <div class="notice_container" v-for="(notice,index) in noticesData" :key="index">
                <div class="notice_title" v-if="type==='2'">{{ notice.title }}</div>
                <div class="notice_content" v-if="type==='2'" v-html="notice.content"></div>
                <div class="notice_content" v-if="type==='1'">{{ notice.title }}</div>
                <div class="detailBtn" @click="router.push({name:'notice_detail',params:{id:notice.id}})"
                     v-if="type==='1'">
                  <div class="notice_detail">{{ $t('详情') }}</div>
                  <van-icon name="arrow"/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <ServiceComponents/>
</template>

<script setup>
import ServiceComponents from '@/components/ServiceComponents.vue';
import '@/assets/css/notice.css';
import { useRouter } from "vue-router";
import {ref, onMounted, watch} from "vue";
import { refresh } from "@/common/way";
import {getNewList} from "@/assets/js/api/interface";

const router = useRouter();

const switchNotice = ref(false);
const switchNoticeActive = ref(true);
const switchMessage = ref(true);
const switchMessageActive = ref(false);
const type = ref('1');
function switchToggle(str){
  if (str === 'notice'){
    switchNoticeActive.value = true;
    switchNotice.value = false;
    switchMessageActive.value = false;
    switchMessage.value = true;
    type.value = '1';
  }
  if (str === 'message'){
    switchMessageActive.value = true;
    switchMessage.value = false;
    switchNoticeActive.value = false;
    switchNotice.value = true;
    type.value = '2';
  }

  refresh();
}
const content_2 = ref();
watch(type,()=>{
  getNewsNotice()
})

//获取新闻列表(1公告，2消息)
const noticesData = ref([]);
const getNewsNotice = async () => {
  try {
    const result = await getNewList(type.value);
    noticesData.value = result.data;
  }catch (error) {
    console.error(error)
  }
}

onMounted(()=>{
  getNewsNotice()
  refresh();
})
</script>