<template>
  <div style="height: 100%;overflow: auto">
    <div>
      <div class="coinDetail" style="padding-bottom: 20vw">

        <div class="pages_header">
          <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
          <span class="App_header_title">
            <img src="@/assets/imgs/convert.png" class="coin_detail-img" alt="" /> {{coinUp}}/USDT
          </span>
        </div>

        <div class="coinInfo">
          <div class="tradeType">{{ $t('coinDetailPage.交割合约') }}</div>
          <div class="coinInfo_box">
            <div class="coin_priceInfo">
              <div class="priceValue fall">$ {{ coinData.price }}</div>
              <div class="changeValue fall">
                <div class="priceZf" v-if="parseFloat(coinData.increase)<0" style="color: #ff2d6d"><span>{{ coinData.increase }}%</span></div>
                <div class="priceZf" v-else style="color: #36ffdb"><span>{{ coinData.increase }}%</span></div>
              </div>
            </div>
            <div class="coin_baseInfo">
              <div class="baseInfoItem">
                <div class="baseInfoItemTitle">24H{{ $t('coinDetailPage.最低价') }}</div>
                <div class="baseInfoItemValue">{{ coinData.low }}</div>
              </div>
              <div class="baseInfoItem">
                <div class="baseInfoItemTitle">24H{{ $t('coinDetailPage.成交量') }}({{coinUp}})</div>
                <div class="baseInfoItemValue">{{ coinData.amount }}</div>
              </div>
              <div class="baseInfoItem">
                <div class="baseInfoItemTitle">24H{{ $t('coinDetailPage.最高价') }}</div>
                <div class="baseInfoItemValue">{{ coinData.high }}</div>
              </div>
              <div class="baseInfoItem">
                <div class="baseInfoItemTitle">24H{{ $t('coinDetailPage.成交额') }}(USDT)</div>
                <div class="baseInfoItemValue">{{ coinData.vol }}</div>
              </div>
            </div>
          </div>
        </div>

        <!--K LINE FIGURE-->
        <div class="v-kline-chart-wraps bg-theme">
          <div class="option-kline-container">
            <div class="kline-tabs-wrap">
              <div class="kline-tab-item" style="color: darkgray"><div class="kline-tab-label">{{ $t('coinDetailPage.时间') }}</div></div>
              <div id="kline-tab-item1" class="kline-tab-item" @click="selectTime('1M')"><div class="kline-tab-label">1m</div></div>
              <div id="kline-tab-item2" class="kline-tab-item" @click="selectTime('5M')"><div class="kline-tab-label">5m</div></div>
              <div id="kline-tab-item3" class="kline-tab-item" @click="selectTime('30M')"><div class="kline-tab-label">30m</div></div>
              <div id="kline-tab-item4" class="kline-tab-item" @click="selectTime('1H')"><div class="kline-tab-label">1h</div></div>
              <div id="kline-tab-item5" class="kline-tab-item" @click="selectTime('more')"><div class="kline-tab-label">{{ $t('coinDetailPage.更多') }}</div></div>
              <!--点击更多弹出选项-->
              <div class="kline-tab-more-wrap bg-theme" v-if="showTabMore">
                <div class="kline-tab-more-item" @click="selectTime('1D')"><div class="kline-tab-more-label"><span>1D</span></div></div>
                <div class="kline-tab-more-item" @click="selectTime('1W')"><div class="kline-tab-more-label"><span>1W</span></div></div>
                <div class="kline-tab-more-item" @click="selectTime('1MON')"><div class="kline-tab-more-label"><span>1M</span></div></div>
              </div>
            </div>
            <!--K LINE-->
            <div id="tv_chart_container" class="tv_chart_container"></div>
          </div>
        </div>

        <div class="menuTab">
          <div class="tabItem"><div id="tabTxt1" class="tabTxt" @click="selectTab('tabTxt1')"><span>{{ $t('coinDetailPage.委托订单') }}</span></div></div>
          <div class="tabItem"><div id="tabTxt2" class="tabTxt" @click="selectTab('tabTxt2')"><span>{{ $t('coinDetailPage.最新交易') }}</span></div></div>
        </div>

        <!--DATA-->
        <div class="menuBody">
          <div v-if="orderList===null" class="no_data_content ff_NunitoSemiBold" style="min-height: calc(-260px + 100vh);">
            <img src="@/assets/imgs/noData-icon.png" alt="" class="img_no_data menuImg" />
            <div class="no_data_text">{{ $t('coinDetailPage.暂无数据') }}</div>
          </div>

          <div v-if="orderList!==null" class="data_content ff_NunitoSemiBold" style="min-height: calc(-260px + 100vh);">
            <div v-for="(order,index) in orderList" :key="index">
              <div class="orderList" >
                <div class="textHeader">{{ $t('订单号') }}:<span>{{ order.order_no }}</span></div>
                <div class="textBody">
                  <div>{{ $t('产品') }}：<span>{{ order.symbol  }}</span></div>
                  <div>{{ $t('购买数量') }}：<span>{{ order.money  }}</span></div>
                  <div>{{ $t('时间') }}：<span>{{ order.seconds }}{{order.time_unit}}</span></div>
                  <div>{{ $t('购买方向') }}：
                    <span v-if="order.type===1">{{ $t('coinDetailPage.买涨') }}</span>
                    <span v-if="order.type===2">{{ $t('coinDetailPage.买跌') }}</span>
                  </div>
                  <div>{{ $t('开仓时间') }}：<span>{{ order.open_time }}</span></div>
                  <div>{{ $t('平仓时间') }}：<span>{{ order.close_time }}</span></div>
                  <div>{{ $t('盈亏') }}：<span>{{ order.profits }}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--BOTTOM-->
        <div class="opBoxBottom">
          <div class="rateBox" @click="router.push({name:'contracts_orders',params:{coin:coin}})">
            <img class="rateBox_img" src="@/assets/imgs/TransactionRecord.png" alt="" />
            <div class="rateTitle">{{ $t('coinDetailPage.记录') }}</div>
          </div>
          <div class="opItem">
            <div class="opBtn fall" @click="toBuy('up')">
              <div class="opItemTitle"><span>{{ $t('coinDetailPage.买涨') }}</span></div>
            </div>
          </div>
          <div class="opItem">
            <div class="opBtn rise" @click="toBuy('down')">
              <div class="opItemTitle"><span>{{ $t('coinDetailPage.买跌') }}</span></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

<!--  买涨买跌弹出层-->
  <van-popup v-model:show="showBottom" position="bottom" :close-on-click-overlay="false" class="popup-content">
    <div class="popup-head">
      <div class="popup-left">{{coinUp}}/USDT{{ $t('coinDetailPage.交割') }}</div>
      <div class="popup-title"></div>
      <div class="popup-close" @click="showBottom=false">
        <div class="icon uicon icon-guanbi1"><span></span></div>
      </div>
    </div>
    <div class="popup-body">
      <div class="dealInfo">
        <div class="dealBaseInfo">
          <img :src="coinData.logo" alt="" />
          <div class="coinInfo-deal">
            <div class="coinName">{{coinUp}}/USDT</div>
            <div class="coinSymbol"><div id="buyType" class=""><span>{{ $t('coinDetailPage.买涨') }}</span></div></div>
          </div>
        </div>
        <div class="dealTimeInfo">
          <div class="buyTime">
            <img src="@/assets/imgs/icon_time.svg" alt="" class="buyTime-img" />
            <div class="seconds"><span>{{ timeValue }}</span></div>
          </div>
          <div class="buyAmount">{{ amountValue }} </div>
        </div>
      </div>
      <div class="timeBox amountBox">
        <div class="amountBoxTitle">{{ $t('coinDetailPage.交割时间') }}</div>
        <div class="amountInput">
          <div class="walletTypeBox" @click="showTimePicker = true">
            <img src="@/assets/imgs/icon_time.svg" alt="" />
            <div class="walletSymbol"><span>{{ timeValue }}~%</span></div>
            <div class="icon uicon icon-xiala"><span></span></div>
          </div>
          <div class="amountInputOther">
            <div class="trade-tab">
              <div id="buyUp" class="tradeItem tradeBuy" @click="updateBuyStyle('up')">{{ $t('coinDetailPage.买涨') }}</div>
              <div id="buyDown" class="tradeItem tradeSell" @click="updateBuyStyle('down')">{{ $t('coinDetailPage.买跌') }}</div>
              <div class="parallelogram"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="amountBox">
        <div class="amountBoxTitle">{{ $t('coinDetailPage.投资金额') }}</div>
        <div class="amountInput">
          <div class="amountInputOther" style="width: 100% !important;padding-left: 0;">
            <input v-model="inputValue" @input="getAnticipateEarnings" type="text" :placeholder="$t('coinDetailPage.金额')" class="amountInputOther_input" />
          </div>
        </div>
      </div>
      <div class="balanceBox">
        <div class="balanceBoxItem balanceInfo">{{ $t('coinDetailPage.余额') }}: {{ usdtAmount }} </div>
        <div class="balanceBoxItem feeInfo">{{ $t('coinDetailPage.预期收益') }} : {{ anticipateEarnings }} </div>
      </div>
      <div class="confirm-btn" @click="confirmPlaceOrder">
        <div id="orderBtn" class="orderBtn"><span>{{ $t('coinDetailPage.确认下单') }}</span></div>
      </div>
    </div>
  </van-popup>

<!--  时间选择器-->
  <van-popup v-model:show="showTimePicker" position="bottom">
    <van-picker :columns="timeColumns" @cancel="showTimePicker = false" @confirm="onConfirmTime"/>
  </van-popup>

  <ServiceComponents/>
</template>

<script setup>
import ServiceComponents from '@/components/ServiceComponents.vue';
import "@/assets/css/coin_detail.css";
import { useRouter,useRoute } from "vue-router";
import {onBeforeUnmount, onMounted, ref, watch} from "vue";
import { getCandlestickChart} from "@/assets/js/api/chart";
import { showConfirmDialog,showNotify  } from 'vant';
import { useI18n } from "vue-i18n";
import {
  getAccountUsdt, getContractsOrderList,
  getProductDetail,
  getProductKline, getTradingTimes, micOrderOpen,
} from "@/assets/js/api/interface";
import {connectionWebSocket, getTime} from "@/common/way";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const coin = route.params.coin;
const coinUp = ref('');
if (coin.includes('USD')){
  coinUp.value = coin.replace('USD','').trim();
}
if (coin.includes('USDT')){
  coinUp.value = coin.replace('USDT','').trim();
}
//k线图时间选择
const showTabMore = ref(false);
//金额
const amountValue = ref('0');
//预期收益（预期收益=购买金额*收益率(秒数据的profit_ratio字段)）
const anticipateEarnings = ref(0);
//每个时间段对应的收益率
let timeThanYield = {};
//每个时间段对应的ID
let timeThanId = {};
//时间选择器
const timeValue = ref('');
const showTimePicker = ref(false);
const timeColumns = ref([]);
//获取时间配置
const getTimeConfig = async () => {
  const result = await getTradingTimes(coin);
  const timeArray = result.data.seconds;
  for (let i = 0; i < timeArray.length; i++) {
    const time = timeArray[i].time_num+timeArray[i].time_unit;
    if (i===0){
      timeValue.value = time
      anticipateEarnings.value = parseFloat(amountValue.value)*(parseFloat(timeArray[i].profit_ratio)/100)
    }
    timeThanYield[time] = parseFloat(timeArray[i].profit_ratio)/100;
    timeThanId[time] = timeArray[i].para_id;
    timeColumns.value.push({text:time,value:timeArray[i].time_num})
  }
}

//货币数据
const coinData = ref([]);
const coinDataKline = ref([]);//货币K线数据
const socket = ref();

//k线
const time = ref('1M');
let count = 0;
function selectTime(num){
  if (num!=='more'){
    document.getElementById('kline-tab-item1').classList.remove('active');
    document.getElementById('kline-tab-item2').classList.remove('active');
    document.getElementById('kline-tab-item3').classList.remove('active');
    document.getElementById('kline-tab-item4').classList.remove('active');
    document.getElementById('kline-tab-item5').classList.remove('active');
  }
  switch (num){
    case '1M' :
      document.getElementById('kline-tab-item1').classList.add('active');
      break;
    case '5M' :
      document.getElementById('kline-tab-item2').classList.add('active');
      break;
    case '30M' :
      document.getElementById('kline-tab-item3').classList.add('active');
      break;
    case '1H' :
      document.getElementById('kline-tab-item4').classList.add('active');
  }
  if (num === 'more' && count===0){
    showTabMore.value = true;
    count++;
    return;
  }
  if (num === 'more' && count===1){
    showTabMore.value = false;
    count = 0;
  }
  if (num === '1D' || num === '1W' || num === '1MON'){
    document.getElementById('kline-tab-item5').classList.add('active');
    showTabMore.value = false;
    count = 0;
    time.value = num;
    return;
  }
  time.value = num;
  console.log(time.value)
}

//产品详情
const getCoinDetail = async () => {
  try {
    const data = await getProductDetail(coin);
    coinData.value = data.data
    upCoinDataFormat(coinData.value)
  }catch (error) {
    console.error("数据加载失败:"+error)
  }
}

function upCoinDataFormat(data){
  coinData.value.price = parseFloat(data.price).toLocaleString('en-US');
  coinData.value.low = parseFloat(data.low).toLocaleString('en-US');
  coinData.value.amount = parseFloat(data.amount).toLocaleString('en-US');
  coinData.value.high = parseFloat(data.high).toLocaleString('en-US');
  coinData.value.vol = parseFloat(data.vol);
}

//获取产品K线
let candlestickC = null;
const getCoinKline = async () => {
  var elementById;
  try{
    elementById = document.getElementById("loading-loader");
    elementById.style.display = "block";
    const data = await getProductKline(coin,time.value);
    coinDataKline.value = data.data
    candlestickC = getCandlestickChart(coinDataKline.value,null);

  }catch (error){
    console.error("数据加载失败:"+error)
  }finally {
    elementById.style.display = "none";
    const params = JSON.stringify({
      "ticker" : coin,
      "line" : '1M'
    })
    try {
      socket.value.send(params)
      socket.value.onmessage = (event) => {
        const data = JSON.parse(event.data);
        coinData.value = data
        upCoinDataFormat(coinData.value)
        coinDataKline.value = data.kline
        getCandlestickChart(coinDataKline.value,candlestickC);
      }
    }catch (error){
      console.error(error)
    }
  }
}

//绘画k线图
watch(time,()=>{
  console.log(time.value)
  const params = JSON.stringify({
    "ticker" : coin,
    "line" : time.value
  })
  socket.value.send(params)
})

//委托订单、最新交易
const tabType = ref('hold');
function selectTab(id) {
  document.getElementById('tabTxt1').classList.remove('tab_active');
  document.getElementById('tabTxt2').classList.remove('tab_active');
  const item = document.getElementById(id);
  if (id==='tabTxt1'){
    tabType.value = 'hold';
  }
  if (id==='tabTxt2'){
    tabType.value = 'newTra';
  }
  item.classList.add('tab_active');
}

//买涨买跌
const showBottom = ref(false);
let direction = '';
function toBuy(type){
  showBottom.value = true;
  if (type==='up'){
    direction = 'buy';
  }else {
    direction = 'sell';
  }

  setTimeout(()=>{
    updateBuyStyle(type);
  },1)
}

//买涨买跌tab选择且样式修改
function updateBuyStyle(type){
  if (type === 'up'){
    document.getElementById('buyType').classList.remove('down');
    document.getElementById('buyType').classList.add('up');

    document.getElementById('buyDown').classList.remove('fall-checked');
    document.getElementById('buyUp').classList.add('rise-checked');

    document.getElementById('orderBtn').classList.remove('fall-checked');
    document.getElementById('orderBtn').classList.add('rise-checked');

    direction = 'buy';
  }
  if (type === 'down'){
    document.getElementById('buyType').classList.remove('up');
    document.getElementById('buyType').classList.add('down');

    document.getElementById('buyUp').classList.remove('rise-checked');
    document.getElementById('buyDown').classList.add('fall-checked');

    document.getElementById('orderBtn').classList.remove('rise-checked');
    document.getElementById('orderBtn').classList.add('fall-checked');

    direction = 'sell';
  }
}

//时间选择
function onConfirmTime(data){
  showTimePicker.value = false;
  timeValue.value = data.selectedOptions[0].text;
  anticipateEarnings.value = parseFloat(parseFloat(amountValue.value)*timeThanYield[timeValue.value]);
}
//金额输入获取预期收益
function getAnticipateEarnings(){
  anticipateEarnings.value = parseFloat(parseFloat(amountValue.value)*timeThanYield[timeValue.value]);
}

const inputValue = ref('');
watch(inputValue,()=>{
  if (inputValue.value===''){
    amountValue.value = '0';
    return;
  }
  amountValue.value = inputValue.value;
})

//确认下单
const showDialog = ref(false);
function confirmPlaceOrder(){
  if (parseInt(amountValue.value)===0){
    showNotify({type:'warning',message:t('coinDetailPage.请输入投资金额')});
    return;
  }
  showConfirmDialog({
    message: t('coinDetailPage.确认要下单吗'),
    confirmButtonText: t('确认'),  // 修改确认按钮文字
    cancelButtonText: t('取消'),    // 修改取消按钮文字
  })
      .then(() => {
        // on confirm
        //0是余额,对接之后再改成动态
        if (usdtAmount.value<parseInt(amountValue.value)){
          showNotify({type:'danger',message:t('coinDetailPage.余额不足')})
          return;
        }
        //下单
        placeOrder();
        //重新获取usdt余额
        getUsdtWalletAmount();
        //获取委托订单
        contractsOrderList();
        showBottom.value = false;
        amountValue.value = '';
      })
      .catch(() => {
        // on cancel
      });
}

//秒合约下单
const placeOrder = async () => {
  let data = {
    ticker:coin,
    amount:amountValue.value,
    para_id:timeThanId[timeValue.value],
    direction:direction
  };
  try {
    const result = await micOrderOpen(data);
    backMsgSuccess(result.msg)
  }catch (error) {
    console.error(error)
  }
}

//获取账户余额
const usdtAmount = ref(0);
const getUsdtWalletAmount = async () => {
  try {
    const data = await getAccountUsdt();
    usdtAmount.value = data.data.balance;
  }catch (error){
    console.error(error)
  }
}

watch(tabType,()=>{
  contractsOrderList();
})

const orderList = ref([]);
//获取委托订单
const contractsOrderList = async () => {
  orderList.value = [];
  const data = {
    page: 1,
    per_page: 10,
    type: 'hold',
    ticker: coin
  }
  try {
    const result = await getContractsOrderList(data);
    if (tabType.value==='hold'){
      orderList.value = result.data.slice(0,5);
    }else {
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0); // 设置为今天的00:00:00
      const startTimestamp = Math.floor(startOfDay.getTime()/1000);
      const endOfDay = new Date();
      endOfDay.setHours(23, 59, 59, 999); // 设置为今天的23:59:59.999
      const endTimestamp = Math.floor(endOfDay.getTime()/1000);
      for (let i = 0; i < result.data.length; i++) {
        if (result.data[i].open_time>=startTimestamp && result.data[i].open_time<=endTimestamp){
          orderList.value.push(result.data[i])
        }
      }
    }

    for (let i = 0; i < orderList.value.length; i++) {
      orderList.value[i].money = parseFloat(orderList.value[i].money);
      orderList.value[i].profits = parseFloat(orderList.value[i].profits);
      orderList.value[i].open_time = getTime(orderList.value[i].open_time);
      orderList.value[i].close_time = getTime(orderList.value[i].close_time);
    }
  }catch (error) {
    console.error(error)
  }
}

onMounted(()=>{
  const item1 = document.getElementById('kline-tab-item1');
  item1.classList.add('active');
  getCandlestickChart([]);

  const item2 = document.getElementById('tabTxt1');
  item2.classList.add('tab_active');

  getCoinDetail();
  getCoinKline();

  // socket.value = connectionWebSocket('ws://103.197.158.7:2391');
  socket.value = connectionWebSocket('wss://api.kucoinbyftrz.com/ws2');

  //获取usdt钱包余额
  getUsdtWalletAmount();
  //获取时间配置
  getTimeConfig();
  //获取委托订单
  contractsOrderList();
})

onBeforeUnmount(()=>{
  if (socket.value){
    socket.value.close();
    console.log('WebSocket 连接已关闭')
  }
})

function backMsgSuccess(msg){
  showNotify({
    message: msg,
    color: '#fff',
    background: 'green',
  });
}
</script>

<style scoped>

</style>