import en from '@/assets/js/lang/en-US.js'
import zhCN from '@/assets/js/lang/zh-CN.js'
import zhTW from '@/assets/js/lang/zh-TW.js'
import de from '@/assets/js/lang/de-DE.js'
import es from '@/assets/js/lang/es-ES.js'
import fr from '@/assets/js/lang/fr-FR.js'
import it from '@/assets/js/lang/it-IT.js'
import jp from '@/assets/js/lang/ja-JP.js'
import koKR from '@/assets/js/lang/ko-KR.js'
import msMY from '@/assets/js/lang/ms-MY.js'
import ru from '@/assets/js/lang/ru-RU.js'
import th from '@/assets/js/lang/th-TH.js'
import viVN from '@/assets/js/lang/vi-VN.js'
import { createI18n } from 'vue-i18n'

const cur_lang = localStorage.getItem('Language') ? localStorage.getItem('Language') : 'en-US';
let lang = en;
switch (cur_lang){
    case 'zh-CN' :
        lang = zhCN;break;
    case 'zh-TW' :
        lang = zhTW;break;
    case 'de-DE' :
        lang = de;break;
    case 'es-ES' :
        lang = es;break;
    case 'fr-FR' :
        lang = fr;break;
    case 'it-IT' :
        lang = it;break;
    case 'ja-JP' :
        lang = jp;break;
    case 'ko-KR' :
        lang = koKR;break;
    case 'ms-MY' :
        lang = msMY;break;
    case 'ru-RU' :
        lang = ru;break;
    case 'th-TH' :
        lang = th;break;
    case 'vi-VN' :
        lang = viVN;break;
}
console.log(cur_lang)
const i18n = createI18n({
    locale:'cur_lang',
    legacy: false,
    location:cur_lang,
    messages:{
        'cur_lang':lang
    }
})
export default i18n;