<template>
  <div style="height: 100%;background: linear-gradient(179.88deg, rgb(2, 30, 67) 0.11%, rgb(0, 41, 97) 99.9%);overflow: auto">
    <div style="height: 100%">
      <div style="height: 100%">

        <div class="pages_header">
          <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
          <span class="App_header_title"> {{ $t('inviteFriendsPage.邀请朋友') }} </span>
        </div>

        <div class="share_banner_container">
          <h2 class="share_banner_title">{{ $t('inviteFriendsPage.邀请朋友获得推荐奖励') }}</h2>
          <div class="share_banner_text">{{ $t('inviteFriendsPage.好友参与即可获得推荐奖励') }}</div>
        </div>

        <div class="addess_btn" @click="showNotify">
          <img src="@/assets/imgs/inviteFriends.png" alt="" />
          {{ $t('inviteFriendsPage.邀请朋友') }}
        </div>

        <img src="@/assets/imgs/invate-bg.png" alt="" class="inviteBg" />

        <div class="bottom-container" id="bottomContainer">
          <div class="small-bar"></div>

          <header class="header-bar">
            <div style="width: 18px"></div>
            <div>{{ $t('inviteFriendsPage.您的邀请成功') }}</div>
            <div class="hand-box">
              <img src="@/assets/imgs/palm.png" alt="" />
            </div>
          </header>

          <div class="number">
            <div>{{ allAmount }}</div>
            <div>{{ fundAmount }}</div>
            <div>{{ minerAmount }}</div>
          </div>

          <div class="number-label">
            <div>{{ $t('inviteFriendsPage.全部') }}</div>
            <div>{{ $t('inviteFriendsPage.直接') }}</div>
            <div>{{ $t('inviteFriendsPage.间接') }}</div>
          </div>

          <div class="more-font">{{ $t('inviteFriendsPage.更多') }}</div>
        </div>

        <!-- 上滑展示 -->
        <div class="bottom-sheet close" style="pointer-events: auto;" id="bottomSheet">
<!--          遮盖曾-->
          <div class="bottom-sheet_backdrop" style="background: rgba(0,0,0,0.3)" id="backdrop"></div>
<!--          弹出层内容-->
          <div id="bottom-sheet_card" class="bottom-sheet_card fx-default" style="max-width: 640px;max-height: 75%;height: auto;pointer-events: auto;bottom: -500px">

            <div class="bottom-sheet_pan" style="touch-action: pan-x; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
              <div class="bottom-sheet_bar"></div>
            </div>

            <div class="bottom-sheet_content" style="height: 500px;touch-action: pan-x;user-select: none;-whbkit-user-drag: none;-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
              <div id="sheetPan">
                <div class="small-bar"></div>

                <header class="header-bar">
                  <div></div>
                  <div>{{ $t('inviteFriendsPage.您的邀请成功') }}</div>
                  <div></div>
                </header>

                <div class="number">
                  <div>{{ allAmount }}</div>
                  <div>{{ fundAmount }}</div>
                  <div>{{ minerAmount }}</div>
                </div>

                <div class="number-label">
                  <div>{{ $t('inviteFriendsPage.全部') }}</div>
                  <div>{{ $t('inviteFriendsPage.直接') }}</div>
                  <div>{{ $t('inviteFriendsPage.间接') }}</div>
                </div>
              </div>


              <div>
                <div style="height: 100%;overflow: auto;">
                  <div>
                    <div class="commission-record">

                      <div class="tab-box">
                        <div class="tabs">
                          <div class="invite_switch_container">
                            <div class="invite_switch_content">
                              <div class="invite_switch_item" @click="switchToggle('direct')" v-if="switchDirect"><span>{{ $t('inviteFriendsPage.直接收益') }}</span></div>
                              <div class="invite_switch_item active" @click="switchToggle('direct_active')" v-if="switchDirectActive"><span>{{ $t('inviteFriendsPage.直接收益') }}</span></div>

                              <div class="invite_switch_item" @click="switchToggle('indirect')" v-if="switchIndirect"><span>{{ $t('inviteFriendsPage.间接收益') }}</span></div>
                              <div class="invite_switch_item active" @click="switchToggle('indirect_active')" v-if="switchIndirectActive"><span>{{ $t('inviteFriendsPage.间接收益') }}</span></div>
                            </div>
                          </div>
                        </div>
                        <div class="date-box" @click="showBottom = true">
                          <div class="date">{{ currentDate }}</div>
                          <img src="@/assets/imgs/date.png" alt="" v-if="!showBottom" />
                          <img src="@/assets/imgs/date.png" alt="" class="icon-arrow-rotate" v-if="showBottom" />
                        </div>
                      </div>

                      <div class="list_wrapper">
                        <div>
                          <div v-if="incomeRecords.length===0"  class="invite_no_data_content ff_NunitoSemiBold" style="min-height: calc(-260px + 100vh);">
                            <img src="@/assets/imgs/noData-icon.png" alt="" class="invite_img_no_data" />
                            <div class="invite_no_data_text">{{ $t('inviteFriendsPage.无数据') }}</div>
                          </div>
                          <van-list v-model:loading="loading" :finished="finished" finished-text="Load complete"  @load="onLoad">
                            <div v-if="incomeRecords.length!==0" class="data_content ff_NunitoSemiBold" style="max-height: calc(-250px + 100vh);overflow: auto">
                              <div v-for="(incomeRecord,index) in incomeRecords" :key="index" class="orderList">
                                <div style="flex-direction: row !important;padding: 10px;">
                                  <div style="padding-bottom: 10px">{{ $t('佣金') }}：<span>{{ parseFloat(incomeRecord.amount)  }} $</span></div>
                                  <div>{{ $t('时间') }}：<span>{{ incomeRecord.ctime }}</span></div>
                                </div>
                              </div>
                            </div>
                          </van-list>
                        </div>
                      </div>

                      <van-popup v-model:show="showBottom" position="bottom" :style="{ zIndex: '2104' }" class="custom-popup" @click-overlay="pickerClose">
                        <div style="position: relative">
                          <img src="@/assets/imgs/No.png" alt="" class="close_icon" style="position: absolute; top: 10px; right: 10px; width: 22px; z-index: 99999999;" @click="pickerClose"/>
                          <van-picker v-model="currentDatePicker" :columns="columns" :show-toolbar="false" @change="pickerOnChange"/>
                        </div>
                      </van-popup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <ServiceComponents/>

  <van-notify v-model:show="showMsg" type="success">
    <van-icon name="bell" style="margin-right: 4px;" />
    <span>{{ $t('inviteFriendsPage.复制成功') }}</span>
  </van-notify>
</template>

<script setup>
import ServiceComponents from '@/components/ServiceComponents.vue';
import '@/assets/css/invite.css';
import {useRouter} from "vue-router";
import {onMounted, ref, onBeforeUnmount, watch} from "vue";
import useClipboard from 'vue-clipboard3';
import {getMonth, getTime, getTimestamp, refresh} from "@/common/way";
import {getShare, getShareBonus} from "@/assets/js/api/interface";

const router = useRouter();
const { toClipboard } = useClipboard();


const showMsg = ref(false);
let textToCopy = ''
let page = '1';
let size = '10';
const type = ref('1');
let start_time = '';
let end_time = '';

//获取分享链接以及佣金信息
const allAmount = ref(0);//总收益
const fundAmount = ref(0);//直接收益
const minerAmount = ref(0);//间接收益
const getShareUrl = async () => {
  try {
    const result = await getShare();
    textToCopy = result.data.url;
    allAmount.value = result.data.total_profit;
    fundAmount.value = result.data.indirect_profit;
    minerAmount.value = result.data.direct_profit;
  }catch (error) {
    console.error(error)
  }
}

const showNotify = async () => {
    await toClipboard(textToCopy);
    showMsg.value = true;
    setTimeout(() => {
      showMsg.value = false;
    }, 2000);
};

const showBottom = ref(false);

const switchDirect = ref(false);
const switchDirectActive = ref(true);
const switchIndirect = ref(true);
const switchIndirectActive = ref(false);
function switchToggle(str){
  if (str === 'direct'){
    switchDirectActive.value = true;
    switchDirect.value = false;
    switchIndirectActive.value = false;
    switchIndirect.value = true;
    type.value = '1';
  }
  if (str === 'indirect'){
    switchIndirectActive.value = true;
    switchIndirect.value = false;
    switchDirectActive.value = false;
    switchDirect.value = true;
    type.value = '2';
  }

  refresh();
}

//年份列表
const years = [];
// 月份列表
const months = [
  { text: '01', value: '01' },
  { text: '02', value: '02' },
  { text: '03', value: '03' },
  { text: '04', value: '04' },
  { text: '05', value: '05' },
  { text: '06', value: '06' },
  { text: '07', value: '07' },
  { text: '08', value: '08' },
  { text: '09', value: '09' },
  { text: '10', value: '10' },
  { text: '11', value: '11' },
  { text: '12', value: '12' },
];
const columns = ref([
  // 第一列
  years,
  // 第二列
  months
]);
const currentDate = ref("");
const currentDatePicker = ref([]);

function pickerOnChange(picker){
  currentDate.value = picker.selectedValues[0]+"/"+picker.selectedValues[1]
  const month = getMonth(picker.selectedValues[1]);
  const timeStamps = getTimestamp(parseInt(picker.selectedValues[0]),month);
  start_time = timeStamps.startOfMonthInSeconds;
  end_time = timeStamps.endOfMonthInSeconds;
}

watch([type,currentDate],()=>{
  incomeRecords.value = [];
  getShareBonusRecord();
})

function pickerClose(){
  showBottom.value = false;
  refresh();
}

const bottomSheet = ref(null);
const backdrop = ref(null);
const sheetCard = ref(null);
const bottomContainerAreaRect = ref(null);
const sheetPanAreaRect = ref(null);

let startX, startY;
let isWithinArea = false;

function showBottomSheet() {
  bottomSheet.value.classList.remove('close');
  bottomSheet.value.classList.add('opened');
  backdrop.value.classList.add('bottom-sheet_backdrop_show');
  sheetCard.value.style.bottom = '0';
}

function hideBottomSheet() {
  bottomSheet.value.classList.remove('opened');
  bottomSheet.value.classList.add('close');
  backdrop.value.classList.remove('bottom-sheet_backdrop_show');
  backdrop.value.classList.remove('bottom-sheet_backdrop_hide');
  sheetCard.value.style.bottom = '-500px';
}

function onTouchStart(event) {
  const touch = event.touches[0];
  startX = touch.clientX;
  startY = touch.clientY;
  startY = event.touches[0].clientY;
  // Check if the touch start is within the defined area
  if (bottomSheet.value.classList.contains("opened")){
    if (isWithinBounds(startX, startY,sheetPanAreaRect.value,'opened')) {
      isWithinArea = true;
    } else {
      isWithinArea = false;
    }
  }else {
    if (isWithinBounds(startX, startY,bottomContainerAreaRect.value,'close')) {
      isWithinArea = true;
    } else {
      isWithinArea = false;
    }
  }
}

function onTouchMove(event) {
  if (!isWithinArea) {
    return;
  }

  const touch = event.touches[0];
  const endY = touch.clientY;

  const distanceY = startY - endY;

  if (Math.abs(distanceY) > 20) {
    if (distanceY > 0) {
      showBottomSheet(); // Swiping up
    } else {
      hideBottomSheet(); // Swiping down
    }
  }
}
function isWithinBounds(x, y,areaRect,type) {
  if (type === 'opened'){
    areaRect = document.getElementById('sheetPan').getBoundingClientRect();
  }
  return x >= areaRect.left &&
      x <= areaRect.right &&
      y >= areaRect.top &&
      y <= areaRect.bottom;
}

//获取分享佣金记录
const incomeRecords = ref([]);//收益记录
const getShareBonusRecord = async () => {
  const data = {
    page: page,
    size: size,
    type: type.value,
    start_time: start_time,
    end_time: end_time,
  }
  try {
    const result = await getShareBonus(data);
    if (result.data.length<9){
      finished.value = true;
    }
    if (page>1){
      incomeRecords.value.push(...result.data);
    }else {
      incomeRecords.value = result.data;
    }
    for (let i = 0; i < incomeRecords.value.length; i++) {
      console.log(incomeRecords.value[i].ctime)
      incomeRecords.value[i].ctime = getTime(incomeRecords.value[i].ctime);
    }
  }catch (error) {
    console.error(error)
  }
}

const loading = ref(false);
const finished  = ref(false);
let count = 0;
//进行滑动加载数据
const onLoad = async () =>{
  if (count>0){
    page+=1;
  }
  try {
    //获取分享佣金记录
    await getShareBonusRecord();
  }catch (error) {
    console.error("数据获取失败:"+error)
  }finally {
    // 加载状态结束
    loading.value = false;
  }
}

onMounted(()=>{
  refresh();

  //获取分享链接
  getShareUrl();

  const currentYear = new Date().getFullYear();
  console.log("当前年份："+currentYear)
  const currentMonth = new Date().getMonth() + 1;
  console.log("当前月份："+currentMonth)
  for (let year = currentYear; year >= 2000; year--) {
    years.push({ text: String(year), value: String(year) });
  }

  const timeStamps = getTimestamp(currentYear,currentMonth-1);
  start_time = timeStamps.startOfMonthInSeconds;
  end_time = timeStamps.endOfMonthInSeconds;

  currentDatePicker.value[0] = String(currentYear);
  if (currentMonth<10){
    currentDatePicker.value[1] = "0"+String(currentMonth);
    currentDate.value = String(currentYear)+"/0"+String(currentMonth)
  }else {
    currentDatePicker.value[1] = String(currentMonth);
    currentDate.value = String(currentYear)+"/"+String(currentMonth)
  }
  console.log(currentDatePicker.value)

  // document.addEventListener('DOMContentLoaded', DOMContentLoaded);

  bottomSheet.value = document.getElementById('bottomSheet');
  backdrop.value = document.getElementById('backdrop');
  sheetCard.value = document.getElementById('bottom-sheet_card');
  bottomContainerAreaRect.value = document.getElementById('bottomContainer').getBoundingClientRect();

  document.addEventListener('touchstart', onTouchStart, { passive: true });
  document.addEventListener('touchmove', onTouchMove, { passive: true });
  backdrop.value.addEventListener('click', hideBottomSheet);
})

onBeforeUnmount(()=>{
  // document.removeEventListener('DOMContentLoaded', DOMContentLoaded);
  document.removeEventListener('touchstart', onTouchStart);
  document.removeEventListener('touchmove', onTouchMove);
  if (backdrop.value) {
    backdrop.value.removeEventListener('click', hideBottomSheet);
  }
})
</script>

<style scoped>
.commission-record .custom-popup {
  border-radius: 5.33333vw 5.33333vw 0 0;
}
.custom-popup ::v-deep .van-picker-column{
  background: #023469 !important;
}
.custom-popup ::v-deep .van-picker__mask{
  background: transparent !important;
}
.custom-popup ::v-deep .van-picker-column__item{
  color: #79a3cb !important;
}
</style>