import { createApp } from 'vue'
import App from './App.vue'

// 导入路由配置
import router from '@/assets/js/router.js';
//全局样式
import '@/assets/css/index.css';
//导入语言包
import i18n from '@/assets/js/language.js'
//导入Element组件
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 引入 Vant 样式
import 'vant/lib/index.css';
//导入Vant-图标组件
import { Icon } from 'vant';
//浮动气泡
import { FloatingBubble } from 'vant';
//弹出层
import { Popup } from 'vant';
//通知栏
import { NoticeBar } from 'vant';
//轮播
import { Swipe, SwipeItem } from 'vant';
//选择器
import { Picker } from 'vant';
//消息通知
import { Notify } from 'vant';
//搜索
import { Search } from 'vant';
//数字键盘
import { NumberKeyboard } from 'vant';
//弹出框
import { Dialog } from 'vant';
//field输入框
import { Field } from 'vant';
//list列表
import { List } from 'vant';
//图片上传
import { Uploader } from 'vant';

const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(ElementPlus);
app.use(Icon);
app.use(FloatingBubble);
app.use(Popup);
app.use(NoticeBar);
app.use(Swipe);
app.use(SwipeItem);
app.use(Picker);
app.use(Notify);
app.use(Search);
app.use(NumberKeyboard);
app.use(Dialog);
app.use(Field);
app.use(List);
app.use(Uploader);

app.mount('#app');
