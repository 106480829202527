<template>
  <!-- 浮动气泡 -->
  <van-floating-bubble v-model:offset="offset" axis="xy" :style="bubbleStyle" @click="showCenter=true"/>
  <!--弹出层-->
  <van-popup v-model:show="showCenter" :style="{ width: '95%'}" class="setting_popup">
    <div class="close" @click="showCenter=false">
      <img src="@/assets/imgs/关闭.png" alt=""/>
    </div>

    <div class="title_text">Chat with us</div>

    <div class="set_content">
      <div class="list-item" @click="toOnlineService">
        <div class="chat-image">
          <img src="@/assets/imgs/客服.png" alt=""/>
        </div>
        <div class="text"> Online Service</div>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import {ref,onMounted} from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const customer_url = route.meta.customerUrl;

const showCenter = ref(false);

const offset = ref({x: 0, y: 0});
const bubbleStyle = ref({
  backgroundImage: 'url(../assets/imgs/信.png) !important',
  backgroundSize: '100% 100%'
})

function toOnlineService(){
  window.location.href = customer_url;
}

onMounted(()=>{
  const viewportHeight = window.innerHeight;
  const viewportWidth = window.innerWidth;
  offset.value = {
    x: viewportWidth.valueOf()-60,
    y: viewportHeight.valueOf()-125
  }
})
</script>

<style scoped>
.setting_popup {
  box-sizing: border-box;
  border-radius: 1.6vw;
  background: #023469;
  height: 94.93333vw;
  padding: 0;
}

.close {
  position: absolute;
  top: 2.66667vw;
  right: 2.66667vw;
}

.close img {
  width: 5.33333vw;
}

.title_text {
  font-family: Poppins,sans-serif;
  font-size: 5.33333vw;
  font-weight: 600;
  text-align: center;
  color: #fff;
  margin-top: 8vw;
}

.set_content{
  margin-top: 6.13333vw;
  display: flex;
  flex-wrap: wrap;
}
.list-item{
  display: inline-block;
  width: 33%;
  margin-top: 4.8vm !important;
}
.setting_popup .set_content .list-item .chat-image img{
  width: 12.8vw;
  height: 12.8vw;
}
.text{
  font-family: Poppins,sans-serif;
  font-size: 3.73333vw;
  font-weight: 500;
  text-align: center;
  color: #fff;
}
</style>