<template>
  <van-number-keyboard
      :show="showNumKeyboard"
      theme="custom"
      :extra-key="['00', '.']"
      :close-button-text="$t('numKeyboard.完成')"
      @blur="handleBlur"
      @input="handleInput"
      @delete="handleDelete"
      class="num_keyboard"
  />
</template>

<script setup>
import {defineProps, defineEmits, ref, watch, onMounted} from "vue";
import {showNotify} from "vant";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  showNumKeyboard: Boolean,
  inputType: String,
  availableAmount: String,
  currencyRate: Number
});

const emit = defineEmits(['update:showNumKeyboard']);

const showNumKeyboard = ref(props.showNumKeyboard);
const inputType = ref(props.inputType);
const availableAmount = ref(props.availableAmount);
const currencyRate = ref(props.currencyRate);

const handleBlur = () => {
  emit('update:showNumKeyboard', false);
};

const handleInput = (value) => {
  var input = '';
  if (inputType.value === 'send'){
    input = document.querySelector('.send-am-input');
  }else if(inputType.value === 'convert'){
    input = document.querySelector('.convert-input');
  }else if(inputType.value === 'mining'){
    input = document.querySelector('.mining-input');
  }else {
    input = document.querySelector('.convert-input2');
    input.value += value;

    if (input.value!=='' && parseFloat(input.value)!==0){
      if (parseFloat(input.value)/currencyRate.value>parseFloat(availableAmount.value)){
        //默认展示时长为3s
        showNotify({
          message: t('numKeyboard.金额不能超过可用金额'),
          color: '#fff',
          background: '#ff976a',
        });
        return;
      }
      document.querySelector('.convert-input').value = parseFloat(input.value)/currencyRate.value;
    }

    return;
  }
  if (parseFloat(input.value+value)>parseFloat(availableAmount.value)){
    //默认展示时长为3s
    showNotify({
      message: t('numKeyboard.输入金额不能超过可用金额'),
      color: '#fff',
      background: '#ff976a',
    });
    return;
  }
  //校验
  if (input.value === '' && value==='.'){
    return;
  }
  if (input.value === '' && value==='00'){
    input.value = '0';
    return;
  }
  if (input.value.includes('.') &&  value==='.'){
    return;
  }
  if (input.value==='0' && value==='00'){
    return;
  }
  if (input.value==='0' && value!=='0' && value!=='.' && value!=='00'){
    input.value = value;
    return;
  }
  if (input.value.length === 10){
    return;
  }
  input.value += value
  if (inputType.value === 'convert' && (input.value!=='' && parseFloat(input.value)!==0)){
    var input2 = document.querySelector('.convert-input2');
    input2.value = parseFloat(input.value)*currencyRate.value;
  }
  if (inputType.value === 'convert' && (input.value==='' || parseFloat(input.value)===0)){
    var input2_1 = document.querySelector('.convert-input2');
    input2_1.value = '';
  }
};

const handleDelete = () => {
  var input = '';
  if (inputType.value === 'send'){
    input = document.querySelector('.send-am-input');
  }else if(inputType.value === 'convert'){
    input = document.querySelector('.convert-input');
  }else if(inputType.value === 'mining'){
    input = document.querySelector('.mining-input');
  }else {
    input = document.querySelector('.convert-input2');
  }
  input.value = input.value.slice(0, -1);
  if (inputType.value === 'convert' && (input.value!=='' && parseFloat(input.value)!==0)){
    var input2 = document.querySelector('.convert-input2');
    input2.value = parseFloat(input.value)*currencyRate.value;
  }
  if (inputType.value === 'convert' && (input.value==='' || parseFloat(input.value)===0)){
    var input2_1 = document.querySelector('.convert-input2');
    input2_1.value = '';
  }
  if (inputType.value === 'convert2' && (input.value!=='' && parseFloat(input.value)!==0)){
    if (parseFloat(input.value)/currencyRate.value>parseFloat(availableAmount.value)){
      //默认展示时长为3s
      showNotify({
        message: t('numKeyboard.金额不能超过可用金额'),
        color: '#fff',
        background: '#ff976a',
      });
      return;
    }
    var input1 = document.querySelector('.convert-input');
    input1.value = parseFloat(input.value)/currencyRate.value;
  }
};

// Watch props to update reactive states
watch(() => props.showNumKeyboard, (newValue) => showNumKeyboard.value = newValue);
watch(() => props.inputType, (newValue) => inputType.value = newValue);
watch(() => props.availableAmount, (newValue) => availableAmount.value = newValue);
watch(() => props.currencyRate, (newValue) => currencyRate.value = newValue);
</script>

<style scoped>
.num_keyboard{
  padding-bottom: 0;
}
.num_keyboard ::v-deep .van-number-keyboard__body{
  background: #023469 !important;
}
.num_keyboard ::v-deep .van-number-keyboard__body .van-key__wrapper .van-key{
  background: #5789bd;
  color: #fff;
  font-family: Poppins,sans-serif;
  font-size: 5.33333vw;
  font-weight: 500;
  line-height: 6.4vw;
  letter-spacing: 0;
  text-align: center;
}
.num_keyboard ::v-deep .van-number-keyboard__body .van-key__wrapper .van-key--active{
  background-color: rgba(87,137,189,0.7) !important; /* 修改按钮点击效果颜色 */
}
.num_keyboard ::v-deep .van-number-keyboard__body .van-key__wrapper .van-key--blue {
  background: #1a9ffd !important;
}
</style>