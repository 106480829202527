import { init, dispose } from 'klinecharts';

var timeoutId;
//获取面积图数据
function getAreaData(_data){
    //初始化面积图
    let domArr = document.querySelectorAll('.lineBoard');
    domArr.forEach((item) => {
        const child = item.children;
        for(let i = 0; i < child.length; i++){
            child[i].remove();
        }
    })
    // 清除前一次的延迟执行
    if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
    }
    //绘画面积图
    timeoutId = setTimeout(() => {
        _data.forEach((coin,index)=>{
            ViveAreaLine(coin.symbol,coin.kline)
        })
        timeoutId = null;
    }, 1000);
}

let chart = '';
//绘画面积图
function ViveAreaLine(_class,_data){
    let parent = document.getElementById(_class);
    try{
        chart = init(parent);

        chart.setStyles({
            grid:{
                show:false,
            },
            candle:{
                type:'area',
                area:{
                    point: {
                        show: false,
                    },
                    style:{
                        line:{
                            style:'none'
                        }
                    },
                    backgroundColor: [{
                        offset: 0,
                        color: ''
                    }, {
                        offset: 1,
                        color: ''
                    }],
                },
                tooltip:{
                    showRule:'none'
                },
                priceMark:{
                    show:false
                },
            },
            xAxis: {//x轴线
                axisLine:{
                    show:false
                },
                tickLine:{
                    show:false
                },
                tickText:{
                    show:false
                }
            },
            yAxis:{//y轴线
                show:false
            },
            crosshair:{
                show:false,
                horizontal:{
                    show:false,
                    line:{
                        show:false,
                    }
                }
            }
        })

        chart.setZoomEnabled(false) //放大

        // chart.setScrollEnabled(false)  //拖拽

        chart.applyNewData(_data)
        // 确保图表显示所有数据点
        chart.scrollByDistance(50,300);
    }catch{
        console.log('数据未加载成功')
    }
}

//获取对应货币的蜡烛图
function getCandlestickChart(data,candlestickCAlready){
    // 初始K线图
    if (!candlestickCAlready){
        console.log("初始K线数据1")
        let domArr = document.querySelectorAll('.tv_chart_container');
        domArr.forEach((item) => {
            const child = item.children;
            for(let i = 0; i < child.length; i++){
                child[i].remove();
            }
        })
    }

    //绘画k线图
    return viewCandlestickChart('tv_chart_container',data,candlestickCAlready)
}

let candlestickC = '';
function viewCandlestickChart(_class,_data,candlestickCAlready){
    let parent = document.getElementById(_class);
    try{
        if (candlestickCAlready){
            console.log("更新K线数据")
            candlestickCAlready.updateData(_data[_data.length-1])
        }else {
            console.log("初始K线数据2")
            candlestickC = init(parent);
            candlestickC.setStyles({
                candle:{
                    tooltip:{
                        showRule:"none"
                    },
                    priceMark: {
                        high:{
                            show:false
                        },
                        low:{
                            show:false
                        },
                    },
                },
                crosshair:{
                    show:false
                },
                xAxis:{
                    axisLine:{
                        show:false
                    }
                },
                yAxis:{
                    axisLine:{
                        show:false
                    }
                },
                indicator:{
                    tooltip:{
                        showName:false,
                        text:{
                            marginLeft:2,
                            marginRight:4
                        }
                    }
                }
            })
            candlestickC.applyNewData(_data);
            candlestickC.createIndicator({
                name: 'MA',
                shortName: 'MA',
                calcParams : [5,10,30],
                figures : [
                    { key: 'ma1', title: 'MA5: ', type: 'line' },
                    { key: 'ma2', title: 'MA10: ', type: 'line' },
                    { key: 'ma3', title: 'MA30: ', type: 'line' }
                ],
                // 当计算参数改变时，希望提示的和参数一样，即title的值需要改变
                regenerateFigures: (params) => {
                    return params.map((p,i) => {
                        return { key: `ma${i + 1}`, title: `MA${p}: `, type: 'line' }
                    })
                },
                //计算结果
                calc: (_data,{calcParams,figures}) => {
                    const closeSums = []
                    return _data.map((kLineData, i) => {
                        const ma = {}
                        const close = kLineData. close
                        calcParams.forEach((param, j) => {
                            closeSums[j] = (closeSums[j] || 0) + close
                            if (i >= param - 1) {
                                ma[figures[j].key] = closeSums[j] / param
                                closeSums[j] -= _data[i - (param - 1)].close
                            }
                        })
                        // 如果有值的情况下，这里每一项的数据格式应该是 { ma1: xxx, ma2: xxx }
                        // 每个key需要和figures中的子项key对应的值一致
                        return ma
                    })
                }
            },true,{
                id: 'candle_pane',
                gap: { top: 0.2,bottom: 0.1 },
                axisOptions: { scrollZoomEnabled: true }
            },() => {})
        }



        return candlestickC;
    }catch{
        console.log('数据未加载成功')
    }
}

export {
    getAreaData,
    getCandlestickChart
}

export default {
    install(app){
        app.config.globalProperties.$getAreaData=getAreaData;
        app.config.globalProperties.$getCandlestickChart=getCandlestickChart;
    }
}