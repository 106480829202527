<template>
  <div style="height: 100%;background: linear-gradient(179.88deg, rgb(2, 30, 67) 0.11%, rgb(0, 41, 97) 99.9%);overflow: auto">
    <div style="height: 100%">
      <div style="height: 100%">

        <div class="pages_header">
          <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
          <span class="App_header_title"> {{ $t('accountPage.账户') }} </span>
          <img src="@/assets/imgs/my.png" alt="" class="App_icon_my" @click="goToHomePage('true')" />
        </div>

        <div class="account_container">
          <h2 class="account_title"> {{ $t('accountPage.立即发送加密货币') }} </h2>
          <div class="account_text"> {{ $t('accountPage.选择一个钱包来发送加密货币') }} </div>
        </div>

        <div class="account_wallet_select">
          <div class="wallet_header">
            <div class="value"> {{ $t('accountPage.选择一个钱包') }} </div>
            <van-search v-model="coinValue" :placeholder="$t('accountPage.搜索')" class="search-input" @update:model-value="searchWallet" />
          </div>

          <div class="wallet_list">
            <div v-for="(wallet,index) in filteredWallets" :key="index">
              <div class="wallet_item" @click="router.push({name:'wallet',params:{id:wallet.id}})">
                <div class="item_info">
                  <img :src="wallet.currency_logo" class="wallet_icon">
                  <div class="wallet_info">
                    <div style="color: rgb(255, 255, 255);"> {{wallet.currency_symbol}} {{ $t('accountPage.钱包') }} </div>
                    <div class="subtitle"> {{wallet.currency_symbol}} Coin </div>
                  </div>
                </div>
                <div class="item_value">
                  <div v-if="wallet.currency_symbol!=='USDT'" class="value_us" style="font-weight: 400;"> ≈ US$ {{ parseFloat(wallet.currency_rate*wallet.legal_balance) }} </div>
                  <div v-if="wallet.currency_symbol==='USDT'" class="value_us" style="font-weight: 400;"> ≈ US$ {{ parseFloat(wallet.legal_balance) }} </div>
                  <div class="value_num" style="font-size: 12px;"> {{ parseFloat(wallet.legal_balance) }} {{wallet.currency_symbol}} </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <ServiceComponents/>
</template>

<script setup>
import ServiceComponents from '@/components/ServiceComponents.vue';
import '@/assets/css/account.css';
import {computed, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {getWalletList} from "@/assets/js/api/interface";

const router = useRouter();

function goToHomePage(bol){
  router.push('/home');
  localStorage.setItem('paramBol',bol);
}

//获取钱包列表
const walletsData = ref();
const findWalletList = async () => {
  const data = await getWalletList('');
  walletsData.value = data.data
}

//搜索钱包
const coinValue = ref("");
function searchWallet(value){
  coinValue.value = value;
}

// 计算属性，用于筛选钱包列表
const filteredWallets = computed(() => {
  // 将输入的值转为大写进行不区分大小写的匹配
  const query = coinValue.value.toUpperCase();
  // 如果查询为空，返回所有钱包
  if (!query) {
    return walletsData.value;
  }
  // 筛选钱包数据
  return walletsData.value.filter(wallet =>
      wallet.currency_symbol.toUpperCase().includes(query)
  );
});

onMounted(()=>{
  findWalletList();
})
</script>

<style scoped>
  .wallet_header ::v-deep .van-search{
    background: #023469 !important;
    border-radius: 1.6vw;
  }
  .search-input ::v-deep .van-search__content{
    background: transparent !important;
  }
  .search-input ::v-deep .van-field__left-icon {
    margin-right: 2.66667vw !important;
  }
  .search-input ::v-deep .van-icon-search:before {
    content: "";
    display: block;
    background: url("@/assets/imgs/search.png");
    width: 5.33333vw;
    height: 5.33333vw;
    background-size: cover;
  }
  .search-input ::v-deep .van-icon:before {
    color: #466fff;
    font-weight: bolder;
  }

  .search-input ::v-deep input {
    color: #fff;
  }
  .search-input ::v-deep input::placeholder {
    color: #5d89b4;
    opacity: 1;
  }
</style>