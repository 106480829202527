<template>
  <div style="height: 100%;background: linear-gradient(179.88deg, rgb(2, 30, 67) 0.11%, rgb(0, 41, 97) 99.9%); overflow: auto;">
    <div style="height: 100%">
      <div style="height: 100%">

        <div class="pages_header">
          <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
          <span class="App_header_title"> {{ $t('languagesPage.语言') }} </span>
        </div>

        <div class="languages_list">
          <div class="lang_item" v-for="(language,index) in languagesData" :key="index">
            <span class="lang-wrap" @click="selectLang(language.code)">
              <img :src="'../assets/imgs/languages/'+(language.code)+'.png'">
              <span>{{ language.name }}</span>
            </span>
            <div class="img-box" v-if="selected_lang === language.code">
              <img src="@/assets/imgs/Yes.png" class="icon_select">
            </div>
          </div>
        </div>

        <div class="submit_container" @click="toggleLang()">
          <div class="submit_btn"> {{ $t('languagesPage.确认') }} </div>
        </div>

      </div>
    </div>
  </div>

  <ServiceComponents/>
</template>

<script setup>
import ServiceComponents from '@/components/ServiceComponents.vue';
import '@/assets/css/languages.css';
import {useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import { useI18n } from 'vue-i18n';

const { setLocaleMessage, locale } = useI18n();

const router = useRouter();

const languagesData = ref([
  {"code": "de-DE","name": "Deutsch"},
  {"code": "en-US","name": "English"},
  {"code": "es-ES","name": "Español"},
  {"code": "fr-FR","name": "français"},
  {"code": "it-IT","name": "italiano"},
  {"code": "ja-JP","name": "Japanese"},
  {"code": "ko-KR","name": "한국어"},
  {"code": "ms-MY","name": "Bahasa Melayu"},
  {"code": "ru-RU","name": "русский"},
  {"code": "th-TH","name": "ไทย"},
  {"code": "vi-VN","name": "Tiếng Việt"},
  {"code": "zh-CN","name": "简体中文"},
  {"code": "zh-TW","name": "繁體中文"}
])

const selected_lang = ref("");
function selectLang(lang){
  selected_lang.value = lang;
}

function toggleLang(){
  localStorage.setItem("Language",selected_lang.value);
  import(`@/assets/js/lang/${selected_lang.value}.js`).then(messages => {
    setLocaleMessage(selected_lang.value, messages.default);
    locale.value = selected_lang.value;
  }).catch(error => {
    console.error(`Failed to load ${selected_lang.value} messages`, error);
  });
  setTimeout(()=>{
    router.go(-1);
  },100)
}

onMounted(()=>{
  selected_lang.value = localStorage.getItem('Language') ? localStorage.getItem('Language') : 'en-US';
})
</script>
