<template>
  <div style="height: 100%;overflow: auto">
    <div>
      <div class="recharge extraBottom">

        <div class="pages_header">
          <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
          <span class="App_header_title"> {{walletInfo.currency_symbol}} {{ $t('walletPage.钱包') }} </span>
          <img src="@/assets/imgs/TransactionRecord.png" alt="" class="App_icon_my" @click="router.push({ name: 'trading_record', params:{id:currencyID} })"/>
        </div>

        <div class="amount">
          <div class="coin_num">
            <div style="height: 70px">
              <img :src="walletInfo.currency_logo" alt="" class="coin_icon" />
            </div>
            <div class="t1">
              <span class="fs14" style="color:white"> {{ $t('walletPage.可用') }}: </span>
              <span style="color:#ffb800">{{ parseFloat(walletInfo.legal_balance) }} {{walletInfo.currency_symbol}}</span>
              <div v-if="walletInfo.currency_symbol!=='USDT'" class="fs14" style="color:#ffb800"> ≈ $ {{ parseFloat(walletInfo.currency_rate)*parseFloat(walletInfo.legal_balance) }} </div>
              <div v-else class="fs14" style="color:#ffb800"> ≈ $ {{ parseFloat(walletInfo.legal_balance) }} </div>
              <div class="fs14 m-t-5">
                <span style="color:#79a3cb"> {{ $t('walletPage.冷冻') }}: {{ parseFloat(walletInfo.legal_lock_balance) }} {{walletInfo.currency_symbol}} </span>
              </div>
            </div>
          </div>
        </div>

        <div class="switch_container">
          <div class="switch_content" v-if="walletInfo.currency_symbol === 'BTC' || walletInfo.currency_symbol === 'ETH' || walletInfo.currency_symbol === 'USDT'">
            <div class="switch_item" @click="switchToggle('receive')" v-if="switchReceive"><span>{{ $t('walletPage.接收') }}</span></div>
            <div class="switch_item active" @click="switchToggle('receive_active')" v-if="switchReceiveActive"><span>{{ $t('walletPage.接收') }}</span></div>

            <div class="switch_item" @click="switchToggle('send')" v-if="switchSend"><span>{{ $t('walletPage.发送') }}</span></div>
            <div class="switch_item active" @click="switchToggle('send_active')" v-if="switchSendActive"><span>{{ $t('walletPage.发送') }}</span></div>

            <div class="switch_item" @click="switchToggle('convert')" v-if="switchConvert"><span>{{ $t('walletPage.转换') }}</span></div>
            <div class="switch_item active" @click="switchToggle('convert_active')" v-if="switchConvertActive"><span>{{ $t('walletPage.转换') }}</span></div>
          </div>
          <div class="switch_content" v-else>
            <div class="switch_item active"><span>{{ $t('walletPage.转换') }}</span></div>
          </div>
        </div>

        <div class="main_container">
          <div v-if="switchReceiveActive" class="main_content" style="padding-bottom: 42px">
            <div class="title title-recharge">
              <div class="left"><span>{{ $t('walletPage.存入资金') }}</span></div>
              <div class="right"></div>
            </div>
            <div>
              <div class="light-tab-container" style="margin-top: 10px">
                <div class="light-tab-content">
                  <div class="light-tab-item active">{{walletInfo.currency_symbol}}</div>
                </div>
              </div>
              <div class="qr_content">
                <qrcode-vue :value="walletInfo.address" class="qr_code" />
                <div class="qr_code_value">
                  <div class="code-box" @click="selectAddress">{{chinaAddress}}</div>
                  <img src="@/assets/imgs/copy.png" alt="" @click="copyAddress" />
                </div>

<!--                <van-field-->
<!--                    v-model="rechargeAmount"-->
<!--                    :name="t('接收金额')"-->
<!--                    :label="t('接收金额')"-->
<!--                    type="number"-->
<!--                    placeholder="0.00"-->
<!--                    :rules="[{ required: true, message: t('请填写金额') }]"-->
<!--                    class="jieshoujine"-->
<!--                />-->
<!--                <div style="display: flex;flex-direction: column;align-items: flex-start">-->
<!--                  <span style="color: white;margin-bottom: 10px;margin-top: 20px">{{ $t('上传充值凭证') }}:</span>-->
<!--                  <van-uploader v-model="fileList" :max-count="1" :after-read="handleAfterRead" />-->
<!--                </div>-->

              </div>
            </div>
<!--            <div class="send_action" @click="recharge">{{ $t('充值') }}</div>-->
          </div>

          <div v-if="switchSendActive" class="main_content">
            <div class="flex justify-between">
              <div class="title"><span>{{ $t('walletPage.退出') }}</span></div>
            </div>
            <div class="coin_type">
              <div class="coin_item active"> BTC </div>
            </div>
            <div class="input_content">
              <div class="address">
                <input v-model="addressInput" type="text" placeholder="Receiving Address" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" class="address_input">
                <img src="@/assets/imgs/dustbin.png" class="icon_delete" @click="clearAddressInput">
              </div>
              <div class="address">
                <img :src="walletInfo.currency_logo" class="coin_icon">
                <input ref="sendInput" type="text" placeholder="Amount" readonly="readonly" class="send-am-input" @click="showNumKeyboardCom('send')" >
                <span class="coin_sympol"> BTC <span class="all" @click="maxWithdraw"> | {{ $t('walletPage.最大') }} </span></span>
              </div>
            </div>
            <div class="send_action" @click="withdraw">{{ $t('walletPage.立即发送') }}</div>
          </div>

          <div v-if="switchConvertActive" class="main_content">
            <div class="title"><span>{{ $t('walletPage.转换') }}</span></div>
            <div class="convert input_content">
              <div class="convert-top" style="border-bottom: 1px solid rgb(24,81,141)">
                <div class="left-info flex" style="color: rgb(121,163,203)">
                  <div class="cv-top" style="font-size: 14px;"> {{ $t('walletPage.从') }} </div>
                  <div class="cv-bottom"><span style="font-size: 14px;">({{walletInfo.currency_symbol}})</span></div>
                </div>
                <div class="right-info flex" style="margin-top: 10px">
                  <input ref="convertInput" type="text" placeholder="0.00" readonly="readonly" class="convert-input" @click="showNumKeyboardCom('convert')"
                         style="background: transparent; text-align: left;" >
                  <img src="@/assets/imgs/dustbin.png" @click="clearAddressInput" style="width: 21px;height: 21px;margin-top: 6px">
                  <span class="all" @click="maxWithdraw">{{ $t('walletPage.最大') }}</span>
                </div>
              </div>
              <div class="convert-bottom">
                <div v-if="walletInfo.currency_symbol==='USDT'" class="left-info flex align-start justify-start" @click="showCoinPicker=true">
                  <div class="cv-top" style="color: rgb(121, 163, 203); font-size: 14px;"> {{ $t('walletPage.到') }} </div>
                  <div class="cv-bottom flex align-center justify-between">
                    <span style="color: white; font-size: 14px;">({{ coinValue }})</span>
                  </div>
                </div>
                <div v-else class="left-info flex align-start justify-start">
                  <div class="cv-top" style="color: rgb(121, 163, 203); font-size: 14px;"> {{ $t('walletPage.到') }} </div>
                  <div class="cv-bottom flex align-center justify-between">
                    <span style="color: rgb(121, 163, 203); font-size: 14px;">(USDT)</span>
                  </div>
                </div>
                <div class="right-info flex">
                  <input type="text" placeholder="0.00" readonly="readonly" class="convert-input2" style="background: transparent; color: rgb(255, 255, 255);" @click="showNumKeyboardCom('convert2')">
                </div>
              </div>
              <div class="fee m-t-10" style="color: rgb(121, 163, 203);">
                <span class="m-r-10">{{ $t('walletPage.费用') }}:</span>
                <span>{{walletInfo.fee_rate}}%</span>
              </div>
              <div class="send_action" @click="convertCoin"> {{ $t('walletPage.继续固定') }} </div>
            </div>
          </div>

          <div class="tips">
            <div class="tips_content">
              <div v-if="switchReceiveActive">{{ $t('walletPage.内容1') }}</div>
              <div v-if="switchSendActive" style="text-align: center;">{{ $t('walletPage.内容2') }}</div>
              <div v-if="switchConvertActive">{{ $t('walletPage.内容3') }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <ServiceComponents/>
  <NumberKeyboard
      :showNumKeyboard="showNumKeyboard" :inputType="inputType"
      :availableAmount="availableAmount" :currencyRate="currencyRate"
      @update:showNumKeyboard="showNumKeyboard = $event"/>

  <van-dialog v-model:show="showDialog" :title="$t('walletPage.资金密码')" show-cancel-button class="dialog"
              @confirm="handleConfirm" :cancel-button-text="$t('settingPage.取消')" :confirm-button-text="$t('settingPage.确认')" @cancel="handleCancel">
    <div class="inputDiv">
      <van-field v-model="withdrawPwd" type="password" :label="$t('walletPage.资金密码')" :placeholder="$t('walletPage.请输入资金密码')" />
    </div>
  </van-dialog>

  <van-popup v-model:show="showCoinPicker" position="bottom">
    <van-picker :columns="coinColumns" @cancel="showCoinPicker = false" @confirm="onConfirmTime"/>
  </van-popup>

  <van-popup v-model:show="showChinaPicker" position="bottom">
    <van-picker :columns="chinaColumns" @cancel="showChinaPicker = false" @confirm="onConfirmChina"/>
  </van-popup>
</template>

<script setup>
import NumberKeyboard from '@/components/NumberKeyboard.vue';
import ServiceComponents from '@/components/ServiceComponents.vue';
import '@/assets/css/wallet.css';
import { useRouter,useRoute } from "vue-router";
import { onMounted, ref} from "vue";
import useClipboard from 'vue-clipboard3';
import { refresh } from "@/common/way";
import {
  accountRecharge,
  chinaInfo,
  getWalletList,
  toConvertApi,
  toWithdrawApi,
  uploadFile
} from "@/assets/js/api/interface";
import QrcodeVue from 'qrcode.vue';
import {showNotify} from "vant";
import { useI18n } from "vue-i18n";

const { toClipboard } = useClipboard();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();

//货币详情
const walletInfo = ref({});
//获取用户信息
let userInfo = route.meta.fetchedData;

//tab切换
const switchReceive = ref(false);
const switchReceiveActive = ref(true);
const switchSend = ref(true);
const switchSendActive = ref(false);
const switchConvert = ref(true);
const switchConvertActive = ref(false);
function switchToggle(str){
  if (str === 'receive'){
    switchReceiveActive.value = true;
    switchReceive.value = false;
    switchSendActive.value = false;
    switchSend.value = true;
    switchConvertActive.value = false;
    switchConvert.value = true;
  }
  if (str === 'send'){
    switchSendActive.value = true;
    switchSend.value = false;
    switchReceiveActive.value = false;
    switchReceive.value = true;
    switchConvertActive.value = false;
    switchConvert.value = true;
  }
  if (str === 'convert'){
    switchConvertActive.value = true;
    switchConvert.value = false;
    switchReceiveActive.value = false;
    switchReceive.value = true;
    switchSendActive.value = false;
    switchSend.value = true;
  }
}

//地址链信息
const showChinaPicker = ref(false);
const chinaColumns = ref([]);
const chinaAddress = ref('');
function selectAddress(){
  if (chinaColumns.value.length<2){
    return;
  }
  showChinaPicker.value = true;
}

function onConfirmChina(event){
  showChinaPicker.value = false;
  chinaAddress.value = event.selectedOptions[0].value;
}

async function copyAddress() {
  await toClipboard(chinaAddress.value);
}

function clearAddressInput(){
  var input = '';
  if (switchConvertActive.value){
    input = document.querySelector('.convert-input');
  }else {
    input = document.querySelector('.address_input');
  }
  input.value = '';
}
//充值金额
const rechargeAmount = ref('');
//充值凭证
const fileList = ref([]);
const fileUrl = ref('');
function handleAfterRead(file){
  let formData = null;
  if (file && file.file && file.file instanceof File) {
    let fileInfo = file.file;
    console.log(fileInfo)
    formData = new FormData();
    formData.append('file', fileInfo);
    console.log(formData)
  } else {
    console.error('file.file is not a valid File object');
  }
  console.log(formData)
  //上传
  uploadFiles(formData)
}

const uploadFiles = async (file) => {
  try {
    const result = await uploadFile(file);
    fileUrl.value = result.data.url;
  }catch (error) {
    console.log(error);
  }
}

//充值提交
const recharge = async () => {
  console.log(fileUrl.value)
  if (rechargeAmount.value===''){
    backMsg('请输入金额')
    return
  }
  if (fileUrl.value===''){
    backMsg('请上传图片')
    return
  }
  const data = {
    symbol:walletInfo.value.currency_symbol,
    amount:parseFloat(rechargeAmount.value),
    imgs:fileUrl.value
  }
  try {
    const result = await accountRecharge(data);
    backMsgSuccess(result.msg)

    //重新获取钱包信息
    await getWalletData();
  }catch (error) {
    console.error(error)
  }finally {
    rechargeAmount.value = '';
    fileList.value = []
    fileUrl.value = ''
  }
}

//数字键盘
const showNumKeyboard = ref(false);
const inputType = ref('');
const availableAmount = ref('');
const currencyRate = ref();

function showNumKeyboardCom(type){
  showNumKeyboard.value = true;
  inputType.value = type;
  availableAmount.value = walletInfo.value.legal_balance;
  if (walletInfo.value.currency_symbol==='USDT'){
    currencyRate.value = 1/parseFloat(walletInfo.value.currency_rate); //1BTC=57993.25U => 1U=1/57993.25BTC
  }else {
    currencyRate.value = parseFloat(walletInfo.value.currency_rate);
  }
}

function maxWithdraw(){
  var input = '';
  if (switchConvertActive.value){
    input = document.querySelector('.convert-input');
  }else {
    input = document.querySelector('.send-am-input');
  }
  input.value = walletInfo.value.legal_balance
  if (switchConvertActive.value){
    if (parseFloat(walletInfo.value.legal_balance)>0){
      document.querySelector('.convert-input2').value = parseFloat(input.value)*(1/parseFloat(walletInfo.value.currency_rate))
    }
  }
}

//提现
let withdrawApi = false;
const addressInput = ref('');
const sendInput = ref('');
function withdraw(){
  if (addressInput.value===''){
    backMsg('发送地址不能为空');
    return;
  }
  if (sendInput.value.value==='' || parseInt(sendInput.value.value)===0){
    backMsg('金额必须大于0');
    return;
  }
  if (userInfo.has_safe_password===0){
    backMsg('未设置资金密码');
  }else {
    showDialog.value = true;
    withdrawApi = true;
  }
}

function backMsg(msg){
  showNotify({
    message: t('walletPage.'+msg),
    color: '#fff',
    background: 'red',
  });
}

//转换
let convertApi = false;
const convertInput = ref('');
function convertCoin(){
  if (convertInput.value.value==='' || parseInt(convertInput.value.value)===0){
    backMsg('金额必须大于0');
    return;
  }
  if (userInfo.has_safe_password===0){
    backMsg('未设置资金密码');
  }else {
    showDialog.value = true;
    convertApi = true;
  }
}

//提交事件
const showDialog = ref(false);
const withdrawPwd = ref('');
const handleConfirm = async () => {
  // 处理确认逻辑
  if (withdrawPwd.value === ''){
    showNotify({
      message: t('settingPage.密码不能为空'),
      color: '#fff',
      background: 'red',
    });
    return;
  }
  let data = {};
  if (withdrawApi){//提现
    data = {
      'symbol': walletInfo.value.currency_symbol,
      'amount': parseFloat(sendInput.value.value),
      'address': addressInput.value,
      'mpwd': withdrawPwd.value
    }
    try {
      const result = await toWithdrawApi(data);
      backMsgSuccess(result.msg)
    }catch (error) {
      console.error(error)
    }
  }
  let toCurrency = coinValue.value;
  if (walletInfo.value.currency_symbol!=='USDT'){
    toCurrency = 'USDT'
  }
  if (convertApi){//转换
    data = {
      'from_symbol': walletInfo.value.currency_symbol,
      'to_symbol': toCurrency,
      'amount': convertInput.value.value,
      'mpwd': withdrawPwd.value
    }
    try {
      const result = await toConvertApi(data);
      backMsgSuccess(result.msg)
    }catch (error) {
      console.error(error)
    }
  }
  document.querySelector('.convert-input').value = '';
  document.querySelector('.convert-input2').value = '';
  withdrawPwd.value = '';
  convertApi = false;
  withdrawApi = false;
  //重新获取钱包信息
  await getWalletData();
}

const handleCancel = () => {
  // 处理取消逻辑
  withdrawPwd.value = ''
  convertApi = false;
  withdrawApi = false;
};

//货币选择器
const coinValue = ref('BTC');
const showCoinPicker = ref(false);
const coinColumns = ref([]);
const getCoinColumns = async () => {
  try {
    const result = await getWalletList();
    for (let item of result.data) {
      if (item.currency_symbol==='USDT'){
        continue;
      }
      coinColumns.value.push({text:item.currency_symbol,value:item.currency_symbol})
    }
  }catch (error) {
    console.error(error)
  }
}
let symbol_currency_rate = '';
const onConfirmTime = async (data) => {
  showCoinPicker.value = false;
  coinValue.value = data.selectedOptions[0].text;
  //USDT钱包加密货币选择获取对应汇率
  try {
    const result = await getWalletList(id,coinValue.value);
    walletInfo.value = result.data

    let input = document.querySelector('.convert-input');
    if (parseFloat(walletInfo.value.legal_balance)>0){
      document.querySelector('.convert-input2').value = parseFloat(input.value)*(1/parseFloat(walletInfo.value.currency_rate))
    }
  }catch (error) {
    console.error(error)
  }
}

//获取钱包信息
const id = route.params.id;
const currencyID = ref('')
const getWalletData = async () => {
  if (id==='171'){//USDT钱包
    symbol_currency_rate='BTC';
  }
  try {
    const data = await getWalletList(id,symbol_currency_rate);
    walletInfo.value = data.data
    currencyID.value = walletInfo.value.currency_id;
    //获取链信息
    const chinaData = await chinaInfo(walletInfo.value.currency_symbol)
    chinaAddress.value = chinaData.data.list[0].address;
    for (let listElement of chinaData.data.list) {
      chinaColumns.value.push({text:listElement.blockchain_name,value:listElement.address})
    }
  }catch (error) {
    console.error(error)
  }
}

onMounted(async ()=>{
  await getWalletData();

  if (walletInfo.value.currency_symbol!=='BTC' && walletInfo.value.currency_symbol!=='ETH' && walletInfo.value.currency_symbol!=='USDT'){
    switchReceive.value = true;
    switchReceiveActive.value = false;
    switchSend.value = true;
    switchSendActive.value = false;
    switchConvert.value = false;
    switchConvertActive.value = true;
  }

  await getCoinColumns();

  refresh();
})

function backMsgSuccess(msg){
  showNotify({
    message: msg,
    color: '#fff',
    background: 'green',
  });
}
</script>

<style scoped>
.num_keyboard{
  padding-bottom: 0;
}
.num_keyboard ::v-deep .van-number-keyboard__body{
  background: #023469 !important;
}
.num_keyboard ::v-deep .van-number-keyboard__body .van-key__wrapper .van-key{
  background: #5789bd;
  color: #fff;
  font-family: Poppins,sans-serif;
  font-size: 5.33333vw;
  font-weight: 500;
  line-height: 6.4vw;
  letter-spacing: 0;
  text-align: center;
}
.num_keyboard ::v-deep .van-number-keyboard__body .van-key__wrapper .van-key--active{
  background-color: rgba(87,137,189,0.7) !important; /* 修改按钮点击效果颜色 */
}
</style>