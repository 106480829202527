<template>
  <van-popup v-model:show="show" position="left" :style="{ width: '88%', height: '100%' }" class="menu-container" @click-overlay="handleClickOverlay">

    <div class="menu-page">

      <div class="meun_title line-center"> KuCoin Pro </div>

      <div class="meun_UID"> UID: {{ userInfo.uid }}   </div>

      <div class="menu_btn_box">
        <div class="btn_wallet" @click="router.push('/account')">
          <img src="@/assets/imgs/wallet.png" alt="" />
          <div class="text"> {{ $t('leftPage.钱包') }} </div>
        </div>
      </div>

      <div class="menu_list_box">
        <div class="menu_item" @click="router.push('/arbitrage')">
          <img src="@/assets/imgs/arbitrage.png" alt="" />
          <span>{{ $t('leftPage.套利') }}</span>
        </div>
        <div class="menu_item" @click="router.push('/mining')">
          <img src="@/assets/imgs/mining.png" alt="" />
          <span>{{ $t('leftPage.采矿') }}</span>
        </div>
        <div class="menu_item" @click="router.push('/setting')">
          <img src="@/assets/imgs/setting.png" alt="" />
          <span>{{ $t('leftPage.设置') }}</span>
        </div>
      </div>

      <div class="contact_box align-start-wow">
        <div class="contact_item m-t-15">
          <img src="@/assets/imgs/online.png" alt="" />
          <span class="over-line-2">Online Service</span>
        </div>
      </div>

    </div>

  </van-popup>
</template>

<script setup>
import { ref, watch } from 'vue';
import { defineProps, defineEmits, computed } from 'vue';
import {useRouter} from "vue-router";
import {useRoute} from "vue-router/dist/vue-router";

//获取用户信息
const route = useRoute();
const userInfo = route.meta.fetchedData;

const router = useRouter();

const props = defineProps({
  showLeft: {
    type: Boolean,
    required: true
  }
});

// 定义 emits
const emit = defineEmits(['update:showLeft']);

// 使用 computed 来将 props 映射到组件内部的 show 状态
const show = computed(() => props.showLeft);

// 监听 props.modelValue 的变化并发出 update:modelValue 事件
watch(() => props.showLeft, (newValue) => {
  emit('update:showLeft', newValue);
});

// 处理点击遮盖层事件
const handleClickOverlay = () => {
  emit('update:showLeft', false);
};
</script>

<style scoped>
.menu-container{
  background: linear-gradient(179.88deg, #021e43 .11%, #002961 99.9%) !important;
}

.menu-page {
  width: 100%;
  padding: 18.66667vw 4.26667vw 0;
  box-sizing: border-box;
  background: linear-gradient(179.88deg, #021e43 .11%, #002961 99.9%);
}

.line-center{
  display: flex;
  align-items: center;
  flex-direction: row;
}

.meun_title{
  font-family: Poppins,sans-serif;
  font-size: 5.33333vw;
  font-weight: 600;
  line-height: 9.6vw;
  text-align: left;
  color: #fff;
}

.meun_UID{
  font-family: Poppins,sans-serif;
  font-size: 3.73333vw;
  font-weight: 500;
  line-height: 4vw;
  text-align: left;
  color: #79a3cb;
  margin-top: 2.66667vw;
}

.menu_btn_box{
  width: 100%;
  display: flex;
  margin-top: 5.33333vw;
  justify-content: space-between;
}
.btn_wallet{
  width: 26.13333vw;
  height: 11.2vw;
  border-radius: 1.6vw;
  background: #1a9ffd;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-around;
  padding: 0 2.66667vw;
}
.btn_wallet img{
  display: inline-block;
  width: 5.33333vw;
  height: 5.06667vw;
}
.text{
  height: 10.93333vw;
  line-height: 10.93333vw;
  font-family: Poppins,sans-serif;
  font-size: 3.2vw;
  font-weight: 500;
}

.menu_list_box{
  margin-top: 6.66667vw;
  width: 100%;
  min-height: 77.33333vw;
  height: auto;
  border-radius: 1.6vw;
  background: #023469;
  padding: 5.33333vw 0;
  overflow: hidden;
}
.menu_item{
  width: 26.13333vw;
  height: 24vw;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menu_item img{
  width: 9.06667vw;
}
.menu_item span{
  margin-top: 1.6vw;
  font-family: Poppins,sans-serif;
  font-size: 3.2vw;
  transform: scale(.9);
  font-weight: 500;
  text-align: center;
  color: #fff;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.contact_box{
  margin-top: 2.66667vw;
  width: 100%;
  height: 22.66667vw;
  border-radius: 1.6vw;
  background: #023469;
  display: flex;
  padding: 2.66667vw 0;
  overflow: hidden;
}
.align-start-wow{
  align-items: flex-start !important;
}
.contact_item{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.m-t-15{
  margin-top: 4vw;
}
.contact_item img{
  width: 9.06667vw;
  border-radius: 50%;
}
.contact_item span{
  display: block;
  margin-top: 1.6vw;
  font-family: Poppins,sans-serif;
  font-size: 3.2vw;
  transform: scale(.85);
  font-weight: 500;
  text-align: center;
  color: #fff;
  word-break: break-word;
}
.over-line-2{
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
}
</style>