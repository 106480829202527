export default {
    homePage:{
        '开始赚钱计划':'수익 창출 계획 시작',
        '快速进入':'빠른 접근',
        '市场':'시장',
        '数字货币':'디지털 화폐',
        '涨幅':'상승폭',
        '邀请朋友':'친구 초대',
        '邀请朋友加入':'친구를 초대하여 가입',
        '开始邀请':'초대 시작',
        '查看':'보기',
        'Ai智能套利':'AI 스마트 아비트리지',
        '200家交易所的智能交易':'200개 거래소의 스마트 트레이딩',
        '矿机租赁':'채굴 기기 임대',
        '效率高,回报快':'효율적이며 빠른 수익'
    },

    languagesPage:{
        '语言':'언어',
        '确认':'확인'
    },

    noticeRecord:{
        '通知':'알림',
        '消息':'메시지',
        '无数据':'데이터 없음',
    },

    leftPage:{
        '钱包':'지갑',
        '套利':'아비트리지',
        '采矿':'채굴',
        '设置':'설정',
    },

    accountPage:{
        '账户':'계정',
        '立即发送加密货币':'암호화폐 즉시 전송',
        '选择一个钱包来发送加密货币':'암호화폐를 전송할 지갑 선택',
        '选择一个钱包':'지갑 선택',
        '搜索':'검색',
        '钱包':'지갑',
    },

    walletPage:{
        "钱包": "지갑",
        "可用": "사용 가능",
        "冷冻": "동결",
        "接收": "받기",
        "发送": "보내기",
        "转换": "변환",
        "存入资金": "자금 입금",
        "退出": "로그아웃",
        "最大": "최대",
        "立即发送": "지금 보내기",
        "从": "에서",
        "到": "까지",
        "费用": "수수료",
        "继续固定": "고정 계속",
        "内容1": "위의 주소로 다른 유형의 자산을 보내지 마십시오. 이 작업은 자산 손실을 초래할 수 있습니다. 송금이 성공한 후, 네트워크 노드는 해당 자산의 수령을 확인해야 합니다. 따라서 송금을 완료한 후 온라인 고객 서비스에 연락하여 수령을 확인하십시오.",
        "内容2": "모르는 사람에게 자금을 송금하지 마십시오",
        "内容3": "두 암호화폐 간에 직접 거래할 수 없습니다. 먼저 하나의 암호화폐를 USDT로 변환한 후, USDT를 사용하여 다른 암호화폐로 교환해야 합니다.",
        "资金密码": "자금 비밀번호",
        "请输入资金密码": "자금 비밀번호를 입력하십시오",
        "未设置资金密码": "자금 비밀번호가 설정되지 않았습니다",
        "发送地址不能为空": "송신 주소를 비워둘 수 없습니다",
        "金额必须大于0": "금액은 0보다 커야 합니다"
    },

    tradingPage:{
        '交易记录':'거래 기록',
        '接收':'받기',
        '发送':'보내기',
        '转换':'변환',
        '无数据':'데이터 없음',
    },

    arbitragePage:{
        '主持工作':'작업 관리',
        '总套利':'총 아비트리지',
        '今日收益':'오늘의 수익',
        '托管订单':'위탁 주문',
        '介绍':'소개',
        '人工智能机器人是如何工作的':'AI 로봇 작동 방법',
        '套利产品':'아비트리지 상품',
        'AI智能挖矿':'AI 스마트 채굴',
        '低买高卖获利':'저가 매수, 고가 매도',
    },

    trusteeshipDetailPage:{
        '托管详细':'위탁 상세',
        '主持':'관리',
        '终止':'종료',
        '赎回':'상환',
        '无数据':'데이터 없음',
    },

    miningPage:{
        '你值得信任的量化投資':'신뢰할 수 있는 퀀트 투자',
        '立即开户':'지금 계좌 개설',
        '总流动性矿池金额':'총 유동성 마이닝 풀 금액',
        '质押人數':'스테이킹 인원',
        '质押':'스테이킹',
        '质押记录':'스테이킹 기록',
        '质押收益':'스테이킹 수익',
        '输入要质押的USDT数量':'스테이킹할 USDT 수량 입력',
        '质押总量':'스테이킹 총량',
        '最大限度':'최대 한도',
        '余额':'잔액',
        '钱包余额':'지갑 잔액',
        '收益率':'수익률',
        '选择质押的期限':'스테이킹 기간 선택',
        '没有记录':'기록 없음',
        '挖矿收益地址':'채굴 수익 주소',
        '数量':'수량',
        '相关问题':'관련 문제',
        '什麼是AI量化交易':'AI 퀀트 거래란',
        '文本1':'퀀트 거래는 금융 자산(예: 주식, 옵션, 암호화폐)의 가치를 결정하기 위해 교육과 통계 분석을 강조하는 방법입니다. 퀀트 트레이더는 역사적인 투자 및 주식 시장 데이터를 포함한 다양한 데이터를 사용하여 거래 알고리즘과 컴퓨터 모델을 개발합니다. 이 컴퓨터 모델에서 생성된 정보는 투자 기회를 분석하고 성공적인 거래 전략을 수립하는 데 도움이 됩니다. 일반적으로 이 거래 전략에는 진입 및 종료 지점, 거래의 예상 위험 및 예상 수익에 대한 매우 구체적인 정보가 포함됩니다. 퀀트 분석의 궁극적인 목표는 정량화된 통계 데이터와 지표를 사용하여 투자자가 수익성 있는 투자 결정을 내릴 수 있도록 돕는 것입니다.',
        '为什么要支付手续费':'왜 수수료를 지불해야 하는가',
        '文本2':'퀀트 거래에서 스테이킹 투자를 위해 계좌를 개설해야 하므로, ETH는 계좌 개설 수수료로 청구됩니다.',
        'AI量化交易的优势':'AI 퀀트 거래의 장점',
        '文本3_1':'인간 개입 필요 없음',
        '文本3_2':'AI 퀀트 거래는 인지 오류를 줄입니다',
        '文本3_3':'AI 퀀트 거래 전략으로 효과 배가',
        '文本3_4':'AI 퀀트 전략은 백테스트를 허용',
        '文本3_5':'무제한 전략 실행 가능',
        '文本3_6':'자동 거래로 투자자의 시간을 절약',
        '文本3_7':'퀀트 거래 시스템은 백테스트와 일치',
        '文本3_8':'시스템이 계획에 따라 수행',
        '在哪里进行AI量化交易':'어디서 AI 퀀트 거래를 하나요',
        '文本4':'Coinbase와 협력하여 개발한 퀀트 거래는 모든 프로그램이 Coinbase 지갑에서 실행되는 공개적이고 공정한 AI 퀀트 거래 프로젝트입니다.',
        '为什么会有这项目':'이 프로젝트가 왜 생겼는가',
        '文本5':'2022년, Coinbase와 정식으로 협력하여 새로운 AI+ 금융 생태계 컨센서스 서클 구축을 위해 20억 달러를 공동 투자하고, 퀀트 거래 플랫폼을 출시했습니다. AI와 금융의 완벽한 통합을 실현하며, 핵심 제품은 AI 분석 계산 칩으로, 최신 6.2.1 AI 로봇에 탑재되어 여러 산업의 전통을 깨뜨리고 금융 거래 전통도 포함됩니다. 퀀트 거래 플랫폼을 사용하여 거래가 더 간편해집니다. 다음 단계로서, 새로운 세대의 암호 자산 거래 플랫폼이 계속해서 힘을 발휘하며, Web3.0 버전을 강력히 출시할 예정입니다.',
        '当前总质押':'현재 총 스테이킹',
        '当日收益':'당일 수익',
        '总收益':'총 수익',
    },

    inviteFriendsPage:{
        '邀请朋友':'친구 초대',
        '邀请朋友获得推荐奖励':'친구를 초대하여 추천 보상 받기',
        '好友参与即可获得推荐奖励':'친구가 AI 아비트리지 및 채굴 기기 임대에 참여하면 추천 보상을 받습니다',
        '您的邀请成功':'초대 성공',
        '全部':'전체',
        '直接':'직접',
        '间接':'간접',
        '更多':'더보기',
        '直接收益':'직접 수익',
        '间接收益':'간접 수익',
        '无数据':'데이터 없음',
        '复制成功':'복사 성공',
    },

    coinDetailPage:{
        '交割合约':'선물 계약',
        '最低价':'최저가',
        '成交量':'거래량',
        '最高价':'최고가',
        '成交额':'거래액',
        '时间':'시간',
        '更多':'더보기',
        '委托订单':'주문',
        '最新交易':'최신 거래',
        '暂无数据':'데이터 없음',
        '记录':'기록',
        '买涨':'매수',
        '买跌':'매도',
        '交割':'결제',
        '交割时间':'결제 시간',
        '投资金额':'투자 금액',
        '金额':'금액',
        '余额':'잔액',
        '预期收益':'예상 수익',
        '确认下单':'주문 확인',
        '请输入投资金额':'투자 금액을 입력하세요',
        '确认要下单吗':'주문을 확정하시겠습니까?',
        '余额不足':'잔액 부족',
    },

    contractsOrderPage:{
        '委托订单':'주문',
        '等待中':'대기 중',
        '已结结束':'종료됨',
        '无数据':'데이터 없음',
    },

    numKeyboard:{
        '完成':'완료',
        '输入金额不能超过可用金额':'입력 금액이 사용 가능한 금액을 초과할 수 없습니다',
    },

    settingPage:{
        "设置": "설정",
        "修改资金密码": "자금 비밀번호 변경",
        "旧密码": "기존 비밀번호",
        "请输入旧密码": "기존 비밀번호를 입력하십시오",
        "新密码": "새 비밀번호",
        "请输入新密码": "새 비밀번호를 입력하십시오",
        "确认密码": "비밀번호 확인",
        "请再次输入新密码": "새 비밀번호를 다시 입력하십시오",
        "密码不能为空": "비밀번호는 비워둘 수 없습니다",
        "新密码和确认密码不匹配": "새 비밀번호와 확인 비밀번호가 일치하지 않습니다",
        "取消": "취소",
        "确认": "확인",
        "设置资金密码": "자금 비밀번호 설정",
        "资金密码": "자금 비밀번호"
    },

    interfaceJs: {
        "获取失败": "가져오기 실패",
        "登录失败": "로그인 실패",
        "设置成功": "설정 성공",
        "修改成功": "수정 성공",
        "添加失败": "추가 실패",
        "修改失败": "수정 실패"
    },

    "时间": "시간",
    "数量": "수량",
    "状态": "상태",
    "审核中": "검토 중",
    "已完成": "완료",
    "审核未通过": "검토 실패",
    "购买金额": "구매 금액",
    "全部": "모두",
    "输入金额": "금액 입력",
    "可用余额": "사용 가능한 잔액",
    "大于最大可投": "최대 투자 금액 초과",
    "购买": "구매",
    "购买成功": "구매 성공",
    "请输入购买金额": "구매 금액을 입력하세요",
    "订单号": "주문 번호",
    "购买日期": "구매 날짜",
    "结束日期": "종료 날짜",
    "预计收益": "예상 수익",
    "日收益率": "일일 수익률",
    "取消托管": "보관 취소",
    "接收金额": "수령 금액",
    "请填写金额": "금액을 입력하세요",
    "上传充值凭证": "충전 증명서 업로드",
    "充值": "충전",
    "请上传图片": "이미지를 업로드하세요",
    "请输入金额": "금액을 입력하세요",
    "确认": "확인",
    "产品": "제품",
    "购买数量": "구매 수량",
    "购买方向": "구매 방향",
    "开仓时间": "개시 시간",
    "平仓时间": "종료 시간",
    "盈亏": "손익",
    "取消": "취소",
    "质押数量": "담보 수량",
    "期限": "기간",
    "开始时间": "시작 시간",
    "结束时间": "종료 시간",
    "收益率": "수익률",
    "天": "일",
    "质押金额必须大于0": "담보 금액은 0보다 커야 합니다",
    "请选择质押产品": "담보 제품을 선택하세요",
    "选择质押的产品": "담보 제품 선택",
    "限额": "한도",
    "周期": "주기",
    "预警收益": "경고 수익",
    "数量限制": "수량 제한",
    "最少可投": "최소 투자액",
    "最大可投": "최대 투자액",
    "立即租用": "지금 대여",
    "详情": "상세",
    "收益金额": "수익 금액"
}
