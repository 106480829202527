export default {
    homePage:{
        '开始赚钱计划':'Inizia il Piano di Guadagno',
        '快速进入':'Accesso Rapido',
        '市场':'Mercato',
        '数字货币':'Cryptovaluta',
        '涨幅':'Aumento',
        '邀请朋友':'Invita Amici',
        '邀请朋友加入':'Invita Amici a Unirsi',
        '开始邀请':'Inizia a Invitare',
        '查看':'Visualizza',
        'Ai智能套利':'Arbitraggio Intelligente IA',
        '200家交易所的智能交易':'Trading Intelligente su 200 Exchange',
        '矿机租赁':'Noleggio Minatori',
        '效率高,回报快':'Alta Efficienza, Rendimento Veloce'
    },

    languagesPage:{
        '语言':'Lingua',
        '确认':'Conferma'
    },

    noticeRecord:{
        '通知':'Notifiche',
        '消息':'Messaggi',
        '无数据':'Nessun Dato',
    },

    leftPage:{
        '钱包':'Portafoglio',
        '套利':'Arbitraggio',
        '采矿':'Mining',
        '设置':'Impostazioni',
    },

    accountPage:{
        '账户':'Account',
        '立即发送加密货币':'Invia Cryptovaluta Subito',
        '选择一个钱包来发送加密货币':'Seleziona un Portafoglio per Inviare Cryptovaluta',
        '选择一个钱包':'Seleziona un Portafoglio',
        '搜索':'Cerca',
        '钱包':'Portafoglio',
    },

    walletPage:{
        "钱包": "Portafoglio",
        "可用": "Disponibile",
        "冷冻": "Congelato",
        "接收": "Ricevi",
        "发送": "Invia",
        "转换": "Converti",
        "存入资金": "Deposita Fondi",
        "退出": "Disconnettersi",
        "最大": "Max",
        "立即发送": "Invia Ora",
        "从": "Da",
        "到": "A",
        "费用": "Commissione",
        "继续固定": "Continua Fisso",
        "内容1": "Si prega di non inviare altri tipi di beni all'indirizzo sopra. Questa operazione può comportare la perdita dei tuoi beni. Dopo l'invio riuscito, i nodi della rete devono confermare la ricezione dei beni corrispondenti. Pertanto, contatta il servizio clienti online in tempo per verificare la ricezione quando hai completato il trasferimento.",
        "内容2": "Si prega di non trasferire fondi a sconosciuti",
        "内容3": "Non puoi scambiare direttamente tra due criptovalute. Devi prima convertire una criptovaluta in USDT, e poi utilizzare USDT per scambiare con qualsiasi altra criptovaluta.",
        "资金密码": "Password dei Fondi",
        "请输入资金密码": "Inserisci la password dei fondi",
        "未设置资金密码": "Password dei fondi non impostata",
        "发送地址不能为空": "L'indirizzo di invio non può essere vuoto",
        "金额必须大于0": "L'importo deve essere maggiore di 0"
    },

    tradingPage:{
        '交易记录':'Storico Transazioni',
        '接收':'Ricevi',
        '发送':'Invia',
        '转换':'Converti',
        '无数据':'Nessun Dato',
    },

    arbitragePage:{
        '主持工作':'Arbitraggio',
        '总套利':'Arbitraggio Totale',
        '今日收益':'Guadagno del Giorno',
        '托管订单':'Ordini in Custodia',
        '介绍':'Introduzione',
        '人工智能机器人是如何工作的':'Come Funziona il Robot IA',
        '套利产品':'Prodotti di Arbitraggio',
        'AI智能挖矿':'Mining Intelligente IA',
        '低买高卖获利':'Compra Basso e Vendi Alto per Guadagnare',
    },

    trusteeshipDetailPage:{
        '托管详细':'Dettagli Custodia',
        '主持':'Arbitraggio',
        '终止':'Terminare',
        '赎回':'Riscattare',
        '无数据':'Nessun Dato',
    },

    miningPage:{
        '你值得信任的量化投資':'Investimento Quantitativo di cui Puoi Fidarti',
        '立即开户':'Apri un Conto Subito',
        '总流动性矿池金额':'Importo Totale del Pool di Liquidità',
        '质押人數':'Numero di Staker',
        '质押':'Staking',
        '质押记录':'Storico Staking',
        '质押收益':'Guadagno da Staking',
        '输入要质押的USDT数量':'Inserisci l’Importo di USDT da Stake',
        '质押总量':'Importo Totale Stakato',
        '最大限度':'Limite Massimo',
        '余额':'Saldo',
        '钱包余额':'Saldo del Portafoglio',
        '收益率':'Tasso di Rendimento',
        '选择质押的期限':'Seleziona la Durata dello Staking',
        '没有记录':'Nessun Record',
        '挖矿收益地址':'Indirizzo Guadagni Mining',
        '数量':'Quantità',
        '相关问题':'Domande Frequenti',
        '什麼是AI量化交易':'Cos’è il Trading Quantitativo IA?',
        '文本1':'Il trading quantitativo è un approccio che enfatizza l\'insegnamento e l\'analisi statistica per aiutare a determinare il valore degli asset finanziari (come azioni, opzioni o criptovalute). I trader quantitativi utilizzano vari dati, inclusi dati storici di investimenti e di mercato azionario, per sviluppare algoritmi di trading e modelli computazionali. Questi modelli forniscono informazioni per aiutare gli investitori ad analizzare le opportunità di investimento e sviluppare strategie di trading efficaci. In generale, la strategia di trading includerà informazioni molto specifiche sui punti di entrata e uscita, i rischi e i rendimenti previsti. L’obiettivo finale dell\'analisi finanziaria quantitativa è utilizzare statistiche e indicatori misurabili per aiutare gli investitori a prendere decisioni di investimento profittevoli.',
        '为什么要支付手续费':'Perché Pagare le Spese?',
        '文本2':'Poiché è necessario aprire un conto per l’investimento quantitativo, le spese in ETH sono addebitate come spese di apertura del conto.',
        'AI量化交易的优势':'Vantaggi del Trading Quantitativo IA',
        '文本3_1':'Senza Intervento Umano',
        '文本3_2':'Il trading quantitativo IA riduce gli errori cognitivi',
        '文本3_3':'Le strategie di trading quantitativo IA raddoppiano i risultati',
        '文本3_4':'Le strategie di trading quantitativo IA consentono il backtesting',
        '文本3_5':'È possibile implementare un numero illimitato di strategie',
        '文本3_6':'Il trading automatizzato libera il tempo degli investitori',
        '文本3_7':'I sistemi di trading quantitativo mostrano coerenza tra esecuzione e backtesting',
        '文本3_8':'Il sistema aiuta a seguire il piano',
        '在哪里进行AI量化交易':'Dove Fare Trading Quantitativo IA?',
        '文本4':'Trading quantitativo realizzato in collaborazione con Coinbase, tutti i programmi sono eseguiti nel portafoglio Coinbase, è un progetto di trading quantitativo IA trasparente e giusto.',
        '为什么会有这项目':'Perché esiste questo progetto?',
        '文本5':'Nel 2022, abbiamo collaborato ufficialmente con Coinbase per creare un nuovo cerchio di consenso IA+Finanza. Insieme a Coinbase, abbiamo investito 2 miliardi di dollari per sviluppare una piattaforma di trading quantitativo che realizza una perfetta integrazione tra IA e finanza. Il prodotto principale è il chip di analisi IA, integrato nel nostro ultimo modello di robot IA 6.2.1. Il suo lancio ha rotto e sfidato le tradizioni di molte industrie, inclusa la tradizione del trading finanziario. La piattaforma di trading quantitativo semplifica il trading. Nella prossima fase, come nuova generazione di piattaforma di trading di asset criptografici, continueremo a progredire e lanceremo la versione Web3.0.',
        '当前总质押':'Importo Totale dello Staking Attuale',
        '当日收益':'Guadagno del Giorno',
        '总收益':'Guadagno Totale',
    },

    inviteFriendsPage:{
        '邀请朋友':'Invita Amici',
        '邀请朋友获得推荐奖励':'Invita Amici per Ricevere Premi di Raccomandazione',
        '好友参与即可获得推荐奖励':'Gli amici che partecipano all’arbitraggio IA o al noleggio di minatori riceveranno premi di raccomandazione',
        '您的邀请成功':'La Tua Invito è Stato Accettato',
        '全部':'Tutto',
        '直接':'Diretto',
        '间接':'Indiretto',
        '更多':'Altro',
        '直接收益':'Guadagno Diretto',
        '间接收益':'Guadagno Indiretto',
        '无数据':'Nessun Dato',
        '复制成功':'Copia Riuscita',
    },

    coinDetailPage:{
        '交割合约':'Contratti a Termine',
        '最低价':'Prezzo Minimo',
        '成交量':'Volume di Transazioni',
        '最高价':'Prezzo Massimo',
        '成交额':'Importo delle Transazioni',
        '时间':'Tempo',
        '更多':'Altro',
        '委托订单':'Ordini',
        '最新交易':'Ultime Transazioni',
        '暂无数据':'Nessun Dato',
        '记录':'Record',
        '买涨':'Compra al Rialzo',
        '买跌':'Compra al Ribasso',
        '交割':'Liquidazione',
        '交割时间':'Orario di Liquidazione',
        '投资金额':'Importo Investito',
        '金额':'Importo',
        '余额':'Saldo',
        '预期收益':'Guadagno Atteso',
        '确认下单':'Conferma Ordine',
        '请输入投资金额':'Inserisci l’Importo da Investire',
        '确认要下单吗':'Confermare l’Ordine?',
        '余额不足':'Saldo Insufficiente',
    },

    contractsOrderPage:{
        '委托订单':'Ordini',
        '等待中':'In Attesa',
        '已結束':'Completato',
        '无数据':'Nessun Dato',
    },

    numKeyboard:{
        '完成':'Completato',
        '输入金额不能超过可用金额':'L’importo inserito non può superare il saldo disponibile',
    },

    settingPage:{
        "设置": "Impostazioni",
        "修改资金密码": "Cambia Password dei Fondi",
        "旧密码": "Vecchia Password",
        "请输入旧密码": "Inserisci la vecchia password",
        "新密码": "Nuova Password",
        "请输入新密码": "Inserisci la nuova password",
        "确认密码": "Conferma Password",
        "请再次输入新密码": "Inserisci di nuovo la nuova password",
        "密码不能为空": "La password non può essere vuota",
        "新密码和确认密码不匹配": "La nuova password e la conferma della password non corrispondono",
        "取消": "Annulla",
        "确认": "Conferma",
        "设置资金密码": "Imposta Password dei Fondi",
        "资金密码": "Password dei Fondi"
    },

    interfaceJs: {
        "获取失败": "Recupero fallito",
        "登录失败": "Accesso non riuscito",
        "设置成功": "Impostazioni salvate con successo",
        "修改成功": "Modifica riuscita",
        "添加失败": "Aggiunta fallita",
        "修改失败": "Modifica fallita"
    },

    "时间": "Tempo",
    "数量": "Quantità",
    "状态": "Stato",
    "审核中": "In Revisione",
    "已完成": "Completato",
    "审核未通过": "Revisione Fallita",
    "购买金额": "Importo di Acquisto",
    "全部": "Tutto",
    "输入金额": "Inserisci Importo",
    "可用余额": "Saldo Disponibile",
    "大于最大可投": "Maggiore del Massimo Investimento",
    "购买": "Acquista",
    "购买成功": "Acquisto Avvenuto",
    "请输入购买金额": "Inserisci Importo di Acquisto",
    "订单号": "Numero Ordine",
    "购买日期": "Data di Acquisto",
    "结束日期": "Data di Fine",
    "预计收益": "Profitto Atteso",
    "日收益率": "Tasso di Rendimento Giornaliero",
    "取消托管": "Annulla Custodia",
    "接收金额": "Importo Ricevuto",
    "请填写金额": "Inserisci Importo",
    "上传充值凭证": "Carica Prova di Ricarica",
    "充值": "Ricarica",
    "请上传图片": "Per Favore Carica Immagine",
    "请输入金额": "Inserisci Importo",
    "确认": "Conferma",
    "产品": "Prodotto",
    "购买数量": "Quantità di Acquisto",
    "购买方向": "Direzione di Acquisto",
    "开仓时间": "Orario di Apertura",
    "平仓时间": "Orario di Chiusura",
    "盈亏": "Profitto e Perdita",
    "取消": "Annulla",
    "质押数量": "Quantità di Pegno",
    "期限": "Scadenza",
    "开始时间": "Orario di Inizio",
    "结束时间": "Orario di Fine",
    "收益率": "Tasso di Rendimento",
    "天": "Giorni",
    "质押金额必须大于0": "L'Importo del Pegno Deve Essere Maggiore di 0",
    "请选择质押产品": "Seleziona Prodotto di Pegno",
    "选择质押的产品": "Seleziona Prodotto Pignorado",
    "限额": "Limite",
    "周期": "Ciclo",
    "预警收益": "Profitto di Allerta",
    "数量限制": "Limite di Quantità",
    "最少可投": "Investimento Minimo",
    "最大可投": "Investimento Massimo",
    "立即租用": "Noleggia Ora",
    "详情": "Dettagli",
    "收益金额": "Importo del Reddito"
}
