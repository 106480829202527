<template>

  <div>
    <div class="download Coinbase">

      <div class="img-top">
        <img src="@/assets/imgs/logo.png" class="logo" alt=""/>
      </div>

      <div class="title ff_NunitoBold">
        <span>Get Coinbase Wallet</span>
      </div>

      <div class="subtitle ff_NunitoBold"> The easiest and most secure crypto wallet</div>

      <div class="intro_list">
        <div class="intro_item">
          <van-icon name="success" class="icon_select"/>
          <span class="ff_NunitoBold">Store all your crypto and NFTs in one place</span>
        </div>
        <div class="intro_item">
          <van-icon name="success" class="icon_select"/>
          <span class="ff_NunitoBold">Trade 500+ assets on DEXes and earn interest</span>
        </div>
        <div class="intro_item">
          <van-icon name="success" class="icon_select"/>
          <span class="ff_NunitoBold">No Coinbase account required</span>
        </div>
      </div>

      <div class="intro_img">
        <img src="@/assets/imgs/downloadEWM.png" class="img_phone" alt="">
      </div>

      <div class="download_actions">
        <img src="@/assets/imgs/icon_googlePlay.png" @click="skip(1)" alt=""/>
        <img src="@/assets/imgs/icon_appStore.png" @click="skip(2)" alt=""/>
      </div>
    </div>
  </div>

  <ServiceComponents/>
</template>

<script setup>
import ServiceComponents from '@/components/ServiceComponents.vue';

function skip(num) {
  if (num === 1) {
    // window.location.href = 'https://play.google.com/store/apps/details?id=org.toshi&hl=en&gl=US'
    window.open('https://play.google.com/store/apps/details?id=org.toshi&hl=en&gl=US', '_blank')
  }
  if (num === 2) {
    // window.location.href = 'https://apps.apple.com/us/app/coinbase-wallet-nfts-crypto/id1278383455'
    window.open('https://apps.apple.com/us/app/coinbase-wallet-nfts-crypto/id1278383455', '_blank')
  }
}

// function relationService(){
//   window.location.href = 'https://ymaknufz.gqvslhwxkqnqlmjika.live/index?key=be5d42191cc37170d81ee17cc27b9ca0&lang=en';
// }
</script>

<style scoped>
.download.Coinbase {
  background: url("@/assets/imgs/downloadBG.png") top no-repeat;
  background-size: cover;
}

.download {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: #fff;
  text-align: left;
}

.img-top {
  display: flex;
  align-items: center;
  margin: 2.66667vw;
}

.logo {
  margin: 15.46667vw auto 0;
  width: 19.73333vw;
  height: 19.73333vw;
}

.title {
  margin-top: 6.66667vw;
  font-size: 8.53333vw;
  text-align: center;
  color: #fff;
}

.ff_NunitoBold {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
}

.subtitle {
  color: #fff;
  margin-top: 6.13333vw;
  margin-bottom: 2.66667vw;
  font-size: 4.26667vw;
  text-align: center;
}

.intro_list {
  margin-top: 3.73333vw;
  color: #fff;
}

.intro_item {
  display: flex;
  align-items: flex-start;
  padding: 0 5.06667vw;
}

.icon_select {
  font-size: 4.26667vw;
  color: #fff;
  line-height: 6.4vw;
  margin-right: 3.73333vw;
}

.intro_img {
  margin-top: 9.86667vw;
  text-align: center;
  max-width: 100vw;
  max-height: 57.33333vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_phone {
  min-height: 57.33333vw;
  max-height: 57.33333vw;
  width: auto;
}

.download_actions {
  margin-top: 7.46667vw;
  padding-bottom: 2.66667vw;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
}

.download_actions img:first-child {
  margin-right: 3.46667vw;
}

.download_actions img {
  width: 30.93333vw;
  height: 9.33333vw;
}
</style>