<template>
    <div style="height: 100%;overflow: auto">
        <div>
            <div class="arbitrage" style="padding-bottom: 8vw">

                <div class="pages_header">
                    <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
                    <span class="App_header_title"></span>
                    <img src="@/assets/imgs/my.png" alt="" class="App_icon_my" @click="goToHomePage('true')" />
                </div>

                <div class="account_info">
                    <div class="info_content">
                        <div class="intro_content">
                            <div class="line1"> {{ $t('arbitragePage.主持工作') }} </div>
                            <div class="line2"> US$ {{ PageData.amount_sum }} </div>
                        </div>
                        <div class="info_items">
                            <div class="info_item">
                                <div class="text-color"> {{ $t('arbitragePage.总套利') }} </div>
                                <div class="important-color"> {{ PageData.aready_profit }} </div>
                            </div>
                            <div class="info_item">
                                <div class="text-color"> {{ $t('arbitragePage.今日收益') }} </div>
                                <div class="important-color"> {{ PageData.today_profit }} </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="order">
                    <div class="order_btn" @click="router.push('/trusteeship_detail')">
                        <img src="@/assets/imgs/TransactionRecord.png" class="icon_order">
                        <span> {{ $t('arbitragePage.托管订单') }} </span>
                    </div>
                </div>

                <div class="banner">
                    <div class="banner-title"> {{ $t('arbitragePage.介绍') }} </div>
                    <div class="banner-text"> {{ $t('arbitragePage.人工智能机器人是如何工作的') }} </div>
                </div>

                <div class="product_list">
                    <div class="pro_title"> {{ $t('arbitragePage.套利产品') }} </div>
                    <div class="list_content"></div>
                </div>

                <div class="video_container">
                    <div class="video_content">
                        <div class="video_info">
                            <div class="fs-16"> {{ $t('arbitragePage.AI智能挖矿') }} </div>
                            <div class="tips fs-16"> {{ $t('arbitragePage.低买高卖获利') }} </div>
                        </div>
                        <div class="video_file">
                            <iframe src="https://www.youtube-nocookie.com/embed/LLoKFgfeE0o" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen="allowfullscreen" style="width: 100%; margin: 0px auto;"></iframe>
                        </div>

                        <div class="AiProList">
                            <div class="Pro_Row" v-for="(item, index) in ProList" :key="index">
                                <div class="Pro_info">
                                    <div class="ProRow_1">
                                        <img :src="item.logo" width="130px">
                                    </div>

                                    <div class="ProRow_2">

                                        <div class="Row_1">
                                            <div class="Row_1_Le">
                                                <div>{{ $t('日收益率') }}:{{ item.income_rate }}%</div>
                                            </div>

                                            <div class="Row_1_Le">
                                                <div>{{ $t('限额') }}:{{ item.buy_min }}~{{ item.buy_max }}{{ item.unit }}</div>
                                            </div>

                                            <div class="Row_1_Le">
                                                <div>{{ $t('周期') }}:{{ item.period }}{{ $t("天") }}</div>
                                            </div>

                                            <div class="Row_1_Le">
                                                <div>{{ $t('预警收益') }}:{{ Number(item.period * item.income_rate / 100 * item.buy_max)
                                                    }}{{ item.unit }}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="Btn">
                                    <div class="Btn_info" @click="$router.push({ name: 'ArbitageDetail', params:{ id:item.id }})">
                                        {{ $t("立即租用") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <ServiceComponents />
</template>

<script setup>
import ServiceComponents from '@/components/ServiceComponents.vue';
import '@/assets/css/arbitrage.css';
import { useRouter } from "vue-router";
import { refresh } from '@/common/way';
import { onMounted, ref } from "vue";
import { getReport, getProList } from "@/assets/js/api/interface";

const PageData = ref({})
const router = useRouter();
const ProList = ref([])

function goToHomePage(bol){
  router.push('/home');
  localStorage.setItem('paramBol',bol);
}

onMounted(async()=>{
    refresh();
    const arr = (await getReport()).data;
    ProList.value = (await getProList()).data;
    PageData.value = arr;
})
</script>

<style scoped>

.AiProList{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    color: white;

    .Btn{
        margin: 0 15px;
        margin-top: 15px;
        background: #1a9ffd;
        border-radius: 25px;
    }

    .Btn_info{
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.Pro_Row{
    background: #023b77;
    padding: 15px 0;
}

.Pro_info{
    display: flex;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
}

.ProRow_1{
    width: 40%;
    position: relative;
}

.ProRow_2{
    width: 60%;
}

</style>