import apiClient from "@/assets/js/axios.js";
import { showNotify } from "vant";

//获取数字货币产品列表
export const getProductList = (per_page,page,type,sort)=>{
    return apiClient.post('/product/list',{per_page,page,type,sort})
}

//产品详情
export const getProductDetail = (symbol)=>{
    return apiClient.get('/product/detail',{
        params: {
            ticker:symbol
        }
    })
}

//获取产品K线
export const getProductKline = (ticker,line)=>{
    return apiClient.post('/market/getKline',{ticker,line})
}

//登录
export const login = (data)=>{
    return apiClient.post('/login/getAccount',data)
        .catch(error => {
            backMsg(error.message || error)
        })
}

//获取用户信息
export const getUserInfo = ()=>{
    return apiClient.post('/user/info')
        .catch(error => {
            backMsg(error.message || error)
        })
}

//获取钱包列表or详情
export const getWalletList = (id,symbol_currency_rate)=>{
    return apiClient.get('/wallet/getWalletList',{
        params:{
            id: id,
            symbol_currency_rate:symbol_currency_rate
        }
    })
        .catch(error => {
            backMsg(error.message || error)
        })
}

//设置资金密码或者修改资金密码
export const addOrUpWithdrawPwd = (old_safeword,safeword,confirmation_safeword)=>{
    return apiClient.post('/user/setSafeword',{old_safeword,safeword,confirmation_safeword})
        .then(response => {
            if (old_safeword===''){
                backMsgSuccess('设置成功')
            }else {
                backMsgSuccess('修改成功')
            }
        })
        .catch(error => {
            if (old_safeword===''){
                backMsg(error.message || error)
            }else {
                backMsg(error.message || error)
            }
        })
}

//提现
export const toWithdrawApi = (data)=>{
    return apiClient.post('/wallet/withdraw',data)
        .catch(error => {
            backMsg(error.message || error)
        })
}

//转换
export const toConvertApi = (data)=>{
    return apiClient.post('/wallet/convert',data)
        .catch(error => {
            backMsg(error.message || error)
        })
}

//获取客服链接
export const getServiceUrl = (data)=>{
    return apiClient.post('/common/get_customer_url',data)
        .catch(error => {
            backMsg(error.message || error)
        })
}

//获取账户余额
export const getAccountUsdt = ()=>{
    return apiClient.post('/wallet/getUsdt')
        .catch(error => {
            backMsg(error.message || error)
        })
}

//获取交易时间
export const getTradingTimes = (ticker)=>{
    return apiClient.post('/mic/initData',{ticker})
        .catch(error => {
            backMsg(error.message || error)
        })
}

//秒合约下单
export const micOrderOpen = (data)=>{
    return apiClient.post('/mic/orderOpen',data)
        .catch(error => {
            backMsg(error.message || error)
        })
}

//获取链信息
export const chinaInfo = (coin)=>{
    return apiClient.post('/wallet/getChainInfo',{coin})
        .catch(error => {
            backMsg(error.message || error)
        })
}

//账户充值
export const accountRecharge = (data)=>{
    return apiClient.post('/wallet/deposit',data)
        .catch(error => {
            backMsg(error.message || error)
        })
}

//上传文件
export const uploadFile = (file)=>{
    return apiClient.post('/common/upload', {file})
        .catch(error => {
            backMsg(error.message || error)
        })
}

// 获取记录
export const GetRecordByType = (data) =>{
    return apiClient.post("/wallet/getRecord",data)
        .catch(error => {
            backMsg(error.message || error)
        })
}

//委托订单
export const getContractsOrderList = (data) =>{
    return apiClient.post("/mic/orderList",data)
        .catch(error => {
            backMsg(error.message || error)
        })
}

// 获取套利
export const getReport = async () => {
    return await apiClient.post("/miner/getReport").catch((error) => {
        backMsg(error.message || error);
    });
};

// 获取AI产品
export const getProList = async () => {
    return await apiClient.post("/miner/getProductList").catch((error) => {
        backMsg(error.message || error);
    });
};

// 获取对应的产品详细
export const GetProById = async(data) => {
    return await apiClient.post("/miner/getProduct", data).catch((error) => {
        backMsg(error.message || error);
    });
};

// 购买
export const minerOrder = async(data) => {
    await apiClient.post("/miner/minerOrder", data)
}

// 获取托管
export const getTuoGRecord = async (data) => {
    return await apiClient.post("/miner/getRecord", data).catch((error) => {
        backMsg(error.message || error);
    });
};

// 获取质押产品
export const getMiningProduct = () =>{
    return apiClient.post("/fund/getProductList")
        .catch(error => {
            backMsg(error.message || error)
        })
}

//创建质押订单
export const financeOrder = (data) =>{
    return apiClient.post("/fund/financeOrder",data)
        .catch(error => {
            backMsg(error.message || error)
        })
}

//获取质押报告
export const getMiningReport = () =>{
    return apiClient.post("/fund/getReport")
        .catch(error => {
            backMsg(error.message || error)
        })
}

//获取质押记录
export const getMiningRecord = (data) =>{
    return apiClient.post("/fund/getRecord",data)
        .catch(error => {
            backMsg(error.message || error)
        })
}

//获取质押收益记录
export const getBonusRecord = (data) =>{
    return apiClient.post("/fund/getBonusRecord",data)
        .catch(error => {
            backMsg(error.message || error)
        })
}

//获取新闻列表
export const getNewList = (type) =>{
    return apiClient.post("/common/getNewsList", {type})
        .catch(error => {
            backMsg(error.message || error)
        })
}

//获取新闻列表详情
export const getNewsDetail = (id) =>{
    return apiClient.post("/common/getNewsDetail", {id})
        .catch(error => {
            backMsg(error.message || error)
        })
}

//获取分享信息
export const getShare = ()=>{
    return apiClient.get('/user/getShare')
        .catch(error => {
            backMsg(error.message || error)
        })
}

//获取分享佣金记录
export const getShareBonus = (data) =>{
    return apiClient.post("/user/getShareBonus", data)
        .catch(error => {
            backMsg(error.message || error)
        })
}


function backMsg(msg){
    showNotify({
        message: msg,
        color: '#fff',
        background: 'red',
    });
}
function backMsgSuccess(msg){
    showNotify({
        message: msg,
        color: '#fff',
        background: 'green',
    });
}