<template>
  <div style="height: 100%;overflow: auto">
    <div>
      <div class="contracts_order">
        
        <div class="pages_header">
          <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
          <span class="App_header_title"> {{ $t('contractsOrderPage.委托订单') }} </span>
        </div>

        <div class="switchTab_contracts">
          <div class="tabItem_contracts">
            <div id="waiting" class="tabTitle_contracts" @click="selectHandle('waiting')"><span>{{ $t('contractsOrderPage.等待中') }}</span></div>
          </div>
          <div class="tabItem_contracts">
            <div id="finished" class="tabTitle_contracts" @click="selectHandle('finished')"><span>{{ $t('contractsOrderPage.已結束') }}</span></div>
          </div>
        </div>

        <div>
          <div v-if="orderList===null" class="no_data_content ff_NunitoSemiBold" style="min-height: calc(-260px + 100vh);">
            <img src="@/assets/imgs/noData-icon.png" alt="" class="img_no_data" />
            <div class="no_data_text">{{ $t('contractsOrderPage.无数据') }}</div>
          </div>

          <van-list v-model:loading="loading" :finished="finished" finished-text="Load complete"  @load="onLoad">
            <div v-if="orderList!==null" class="data_content ff_NunitoSemiBold" style="min-height: calc(-260px + 100vh);">
                <div v-for="(order,index) in orderList" :key="index" class="orderList" >
                  <div class="textHeader">{{ $t('订单号') }}:<span>{{ order.order_no }}</span></div>
                  <div class="textBody">
                    <div>{{ $t('产品') }}：<span>{{ order.symbol  }}</span></div>
                    <div>{{ $t('购买数量') }}：<span>{{ order.money  }}</span></div>
                    <div>{{ $t('时间') }}：<span>{{ order.seconds }}{{order.time_unit}}</span></div>
                    <div>{{ $t('购买方向') }}：
                      <span v-if="order.type===1">{{ $t('coinDetailPage.买涨') }}</span>
                      <span v-if="order.type===2">{{ $t('coinDetailPage.买跌') }}</span>
                    </div>
                    <div>{{ $t('开仓时间') }}：<span>{{ order.open_time }}</span></div>
                    <div>{{ $t('平仓时间') }}：<span>{{ order.close_time }}</span></div>
                    <div>{{ $t('盈亏') }}：<span>{{ order.profits }}</span></div>
                  </div>
                </div>
            </div>
          </van-list>
        </div>

      </div>
    </div>
  </div>

  <ServiceComponents/>
</template>

<script setup>
import ServiceComponents from '@/components/ServiceComponents.vue';
import '@/assets/css/contracts_order.css';
import { useRouter,useRoute } from "vue-router";
import {onMounted, ref, watch} from "vue";
import {getTime, refresh} from "@/common/way";
import {getContractsOrderList} from "@/assets/js/api/interface";

const router = useRouter();
const route = useRoute();

//货币参数
const coin = route.params.coin;

const type = ref('waiting');
function selectHandle(str){
  document.getElementById('waiting').classList.remove('active_contracts');
  document.getElementById('finished').classList.remove('active_contracts');
  document.getElementById(str).classList.add('active_contracts');
  type.value = str;
  refresh();
}

watch(type,()=>{
  if (type.value==='waiting'){
    apiType = 'hold';
  }
  if (type.value==='finished'){
    apiType = 'history';
  }
  orderList.value = [];
  contractsOrderList();
})

let page = 1;
let per_page = 10;
const orderList = ref([]);
//获取委托订单
let apiType = 'hold';
const contractsOrderList = async () => {
  const data = {
    page: page,
    per_page: per_page,
    type: apiType,
    ticker: coin
  }
  try {
    const result = await getContractsOrderList(data);
    if (result.data.length<9){
      finished.value = true;
    }
    if (page>1){
      orderList.value.push(...result.data)
    }else {
      orderList.value = result.data;
    }

    for (let i = 0; i < orderList.value.length; i++) {
      orderList.value[i].money = parseFloat(orderList.value[i].money);
      orderList.value[i].profits = parseFloat(orderList.value[i].profits);
      orderList.value[i].open_time = getTime(orderList.value[i].open_time);
      orderList.value[i].close_time = getTime(orderList.value[i].close_time);
    }
  }catch (error) {
    console.error(error)
  }
}

const loading = ref(false);
const finished  = ref(false);
let count = 0;
//进行滑动加载数据
const onLoad = async () =>{
  if (count>0){
    page+=1;
  }
  try {
    //获取委托订单
    await contractsOrderList();
  }catch (error) {
    console.error("数据获取失败:"+error)
  }finally {
    // 加载状态结束
    loading.value = false;
  }
}

onMounted(()=>{
  document.getElementById('waiting').classList.add('active_contracts');

  refresh();
})
</script>

<style scoped>

</style>