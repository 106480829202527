import Web3 from 'web3';
import { ref } from 'vue';

const web3 = ref(null);
const account = ref(null);

export async function initWeb3() {
    if (window.ethereum) {
        try {
            // Request account access if needed
            await window.ethereum.request({ method: "eth_requestAccounts" });
            web3.value = new Web3(window.web3.currentProvider);
            account.value = await web3.value.eth.getAccounts();
            console.log("account.value1--"+account.value)

            // Listen for account changes
            window.ethereum.on('accountsChanged', (accounts) => {
                account.value = accounts[0];
                console.log("account.value2--"+account.value)
            });

            // Listen for network changes
            window.ethereum.on('chainChanged', () => {
                window.location.reload();
            });
        } catch (error) {
            console.error('User denied account access or error occurred:', error);
        }
    } else {
        console.error('MetaMask is not installed');
    }
}


export function getWeb3() {
    return web3.value;
}

export function getAccount() {
    return account.value;
}